import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { apiAffiliateLogin } from '../../api/api-affiliate';
import { InputText } from '../common';
import Modal from '../common/modal';

const formSchema = Yup.object().shape({
    email: Yup.string().email('Invalid').required('Required'),
    code: Yup.string().required('Required'),
});

const data = {
    email: '',
    code: '',
};

const Login = () => {
    const [error, setError] = useState(false);

    return (
        <Modal title="Log In" icon="sign-in-alt" show={true}>
            <Formik
                initialValues={data}
                validationSchema={formSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    setError(false);

                    var result = await apiAffiliateLogin(values.email, values.code);

                    if (result) {
                        window.location = '/';
                        return;
                    }

                    setError(true);
                    setSubmitting(false);
                }}>
                {({ isSubmitting }) => (
                    <Form>
                        <div>
                            <InputText name="email" type="email" label="Email address" />
                            <InputText name="code" type="password" label="Code" />
                            {error && <div className="validation-error ">Login Failed</div>}
                        </div>
                        <div className="flex gap-1 mt-4 justify-center">
                            <button type="submit" className="btn-primary" disabled={isSubmitting}>
                                Log In
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default Login;
