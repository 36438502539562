import React from 'react';

const TableCell = ({ children, text, action, actionLeft, actionRight, header, center, right, colSpan = 1, className, slim = false, ...props }) => {
    let classes = ['table-col'];

    if (slim) {
        classes = ['table-col-slim'];
    }

    if (actionLeft) {
        classes = ['table-col-action-left'];
    }

    if (actionRight) {
        classes = ['table-col-action-right'];
    }

    if (action) {
        classes = ['table-col-action'];
    }

    if (center) {
        classes.push('text-center');
    }

    if (right) {
        classes.push('text-right');
    }

    if (header) {
        classes.push('bg-gray-50 text-gray-500 uppercase');
    }

    if (className) {
        classes.push(className);
    }

    if (header) {
        return (
            <th className={classes.join(' ')} colSpan={colSpan} {...props}>
                {children || text}
            </th>
        );
    }

    return (
        <td className={classes.join(' ')} colSpan={colSpan} {...props}>
            {children || text}
        </td>
    );
};

export default TableCell;
