import moment from 'moment';
import React, { createContext, useCallback, useEffect, useState, useRef } from 'react';
import { apiBookingRestart } from '../../api/api-booking';
import { apiTicketCategoryGet, apiTicketTypesGet } from '../../api/api-reference-data';
import { getTourTypeIdV3, steps } from '../../config';
import { DaysDiff, MaxTicketDate, MinTicketDate } from '../../helpers/date-helpers';

export const Context = createContext({});

export const Provider = ({ children }) => {
    const [ticketCategoryId, setTicketCategoryId] = useState(undefined);
    const [ticketCategory, setTicketCategory] = useState(undefined);
    const [selectedTickets, setSelectedTickets] = useState(undefined);
    const [selectedBoltOns, setSelectedBoltOns] = useState(undefined);
    const [date, setDate] = useState(undefined);
    const [before, setBefore] = useState(undefined);
    const [after, setAfter] = useState(undefined);
    const [allowedBoltOns, setAllowedBoltOns] = useState(undefined);
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);
    const [rangeDays, setRangeDays] = useState(undefined);
    const [currentDate, setCurrentDate] = useState(undefined);
    const [time, setTime] = useState(undefined);
    const [price, setPrice] = useState(0);
    const [priceBoltOns, setPriceBoltOns] = useState(0);
    const [g2BookingId, setG2BookingId] = useState(undefined);
    const [currentStep, setCurrentStep] = useState(undefined);
    const [initialStep, setInitialStep] = useState(undefined);
    const [discountCode, setDiscountCode] = useState(undefined);
    const [singleUseDiscountAmount, setSingleUseDiscountAmount] = useState(undefined);
    const [happyToSitOpposite, setHappyToSitOpposite] = useState(false);
    const [inError, setInError] = useState(false);
    const [cardTry, setCardTry] = useState(0);

    const [contactDetails, setContactDetails] = useState(undefined);
    const [paymentMethod, setPaymentMethod] = useState(undefined);

    const [secondsRemaining, setSecondsRemaining] = useState(undefined);
    const timer = useRef(null);
    const countdownEnd = useRef(null);

    useEffect(() => {
        return () => {
            // eslint-disable-next-line
            clearInterval(timer.current);
        };
    }, []);

    const complete = useCallback(() => {
        clearInterval(timer.current);
        setSecondsRemaining(undefined);
        countdownEnd.current = null;
        timer.current = null;

        localStorage.removeItem('g2BookingId');

        setCurrentStep(20);
    }, []);

    useEffect(() => {
        const init = async () => {
            //Old G2 booking that needs a tidy?
            const oldG2BookingId = localStorage.getItem('g2BookingId');
            if (oldG2BookingId) {
                console.log('Tidy Old In Progress Booking');
                var restartResult = await apiBookingRestart(getTourTypeIdV3(), oldG2BookingId);

                localStorage.removeItem('g2BookingId');

                if (restartResult === 'COMPLETE') {
                    complete();
                }
            }

            const query = new URLSearchParams(window.location.search);
            const categoryId = query.get('tc');
            const tickets = query.get('t');
            const date = query.get('d');
            const bolt = query.get('bolt');
            const boltPattern = /^[0-9]+(,[0-9]+)*$/;
            const before = query.get('b');
            const after = query.get('a');
            const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
            if (before && timePattern.test(before)) {
                setBefore(before);
            }
            if (after && timePattern.test(after)) {
                setAfter(after);
            }
            if (bolt && boltPattern.test(bolt)) {
                setAllowedBoltOns(bolt.split(',').map(Number));
            }

            if (categoryId) {
                const category = await apiTicketCategoryGet(getTourTypeIdV3(), categoryId);

                if (category) {
                    setTicketCategory(category);
                    setTicketCategoryId(categoryId);

                    if (tickets) {
                        const ticketTypes = (await apiTicketTypesGet(getTourTypeIdV3(), categoryId)).filter((x) => !x.isDisabled && (!x.toDate || moment(x.toDate).isSameOrAfter()));
                        const ticketData = [];
                        let covid = false;

                        tickets.split(',').forEach((part) => {
                            const parts = part.split(':');
                            if (parts.length > 1) {
                                const id = parseInt(parts[0]);
                                const amount = parseInt(parts[1]);

                                if (id !== 0 && !isNaN(id) && amount !== 0 && !isNaN(amount)) {
                                    const ticketType = ticketTypes.find((x) => x.id === id);

                                    if (ticketType) {
                                        if (ticketType.askHappyToSitOpposite) {
                                            covid = true;
                                        }

                                        const existing = ticketData.find((x) => x.id === parseInt(parts[0]));
                                        if (existing) {
                                            existing.amount += parseInt(parts[1]);
                                        } else {
                                            ticketData.push({
                                                id: parseInt(parts[0]),
                                                ticketTypeId: parseInt(parts[0]),
                                                ticketTypeName: ticketType.ticketTypeName,
                                                amount: parseInt(parts[1]),
                                                fromDate: ticketType.fromDate,
                                                toDate: ticketType.toDate,
                                                groupSize: ticketType.groupSize,
                                            });
                                        }
                                    }
                                }
                            }
                        });
                        const dates = { min: MinTicketDate(ticketData), max: MaxTicketDate(ticketData) };
                        dates.range = DaysDiff(dates.min, dates.max);
                        setMinDate(dates.min);
                        setMaxDate(dates.max);
                        setRangeDays(dates.range);

                        const totalTickets = ticketData.reduce((totalTickets, ticket) => totalTickets + ticket.amount, 0);

                        setSelectedTickets(ticketData);
                        if (totalTickets > 0 && totalTickets <= category.maxTicketsPerBooking) {
                            if (covid) {
                                setCurrentStep(19);
                            } else {
                                if (moment(date).isValid()) {
                                    setDate(moment(date).toDate());
                                    setCurrentStep(4);
                                } else {
                                    setDate(dates.min);
                                    if (dates.range <= 7) {
                                        setCurrentStep(steps.StepTime);
                                    } else {
                                        setCurrentStep(3);
                                    }
                                }
                            }
                        } else {
                            setCurrentStep(2);
                        }
                    } else {
                        setTicketCategoryId(categoryId);
                        setCurrentStep(2);
                        setInitialStep(2);
                    }
                } else {
                    setCurrentStep(1);
                    setInitialStep(1);
                }
            } else {
                setCurrentStep(1);
                setInitialStep(1);
            }
        };

        if (window.location.pathname.includes('/500') || window.location.pathname.includes('/400')) {
            setInError(true);
        }

        init();
    }, [complete]);

    const restart = useCallback(async () => {
        var restartResult = await apiBookingRestart(getTourTypeIdV3(), g2BookingId);

        localStorage.removeItem('g2BookingId');

        if (restartResult === 'COMPLETE') {
            complete();
        } else {
            //Return to the tickets page, keeping the current ticket category
            const newHref = `${window.location.protocol}//${window.location.hostname}${window.location.port === 443 ? '' : `:${window.location.port}`}/?tc=${ticketCategoryId}&bolt=${(allowedBoltOns || []).join()}`;
            window.location.assign(newHref);
        }
    }, [g2BookingId, ticketCategoryId, allowedBoltOns, complete]);

    useEffect(() => {
        if (g2BookingId) {
            localStorage.setItem('g2BookingId', g2BookingId);
        }
    }, [g2BookingId]);

    const back = useCallback(() => {
        if (currentStep === steps.StepTime && rangeDays <= 7) {
            setCurrentStep(steps.StepTickets);
        } else if (currentStep === 19) {
            setCurrentStep(2);
        } else {
            setCurrentStep((x) => x - 1);
        }
    }, [currentStep, rangeDays]);

    const next = useCallback(() => {
        setCurrentStep((x) => x + 1);
    }, []);

    const selectHappyToSitOpposite = useCallback(
        (answer) => {
            setHappyToSitOpposite(answer);

            setDate(minDate);
            if (rangeDays <= 7) {
                setCurrentStep(steps.StepTime);
            } else {
                setCurrentStep(3);
            }
        },
        [rangeDays, minDate]
    );

    const selectTicketCategoryId = useCallback(async (id) => {
        setTicketCategory(await apiTicketCategoryGet(getTourTypeIdV3(), id));
        setTicketCategoryId(parseInt(id));
        setCurrentStep(2);
    }, []);

    const selectTickets = useCallback((tickets) => {
        const dates = { min: MinTicketDate(tickets), max: MaxTicketDate(tickets) };
        dates.range = DaysDiff(dates.min, dates.max);
        setMinDate(dates.min);
        setMaxDate(dates.max);
        setRangeDays(dates.range);

        let newStep = steps.StepDate;

        //Any need COVID questions?
        tickets.forEach((ticket) => {
            if (ticket.amount > 0 && ticket.askHappyToSitOpposite) {
                newStep = steps.StepCOVID;
            }
        });

        //If no COVID question, then reset the answer of you can end up looking for 6Y, which is impossible
        if (newStep !== steps.StepCOVID) {
            setHappyToSitOpposite(false);
        }

        setDate(dates.min);
        if (newStep !== steps.StepCOVID && newStep === steps.StepDate && dates.range <= 7) {
            newStep = steps.StepTime;
        }

        setCurrentStep(newStep);
        setSelectedTickets(tickets);
    }, []);

    const selectDate = useCallback(
        (e) => {
            setDate(e);
            next();
        },
        [next]
    );

    const calculateSecondsRemaining = useCallback(() => {
        if (countdownEnd.current) {
            const now = moment();
            const remaining = moment(countdownEnd.current).diff(now, 'seconds');
            setSecondsRemaining(remaining);
            if (remaining < 1) {
                clearInterval(timer.current);
                timer.current = null;
                window.location.reload();
            }
        } else {
            setSecondsRemaining(undefined);
        }
    }, [countdownEnd]);

    const startTimer = useCallback(() => {
        countdownEnd.current = moment().add(10, 'minutes');

        clearInterval(timer.current);
        timer.current = setInterval(calculateSecondsRemaining, 1000);
    }, [calculateSecondsRemaining]);

    const selectTime = useCallback((e) => {
        setTime(e);
    }, []);

    const selectPrice = useCallback((e) => {
        setPrice(e);
    }, []);

    const selectPriceBoltOns = useCallback((e) => {
        setPriceBoltOns(e);
    }, []);

    const totalBookingPrice = useCallback(() => {
        const total = price + (priceBoltOns || 0) - (singleUseDiscountAmount || 0);
        if (total < 0) {
            return 0;
        }
        return total;
    }, [price, priceBoltOns, singleUseDiscountAmount]);

    const incrementCardTry = useCallback(() => {
        setCardTry((x) => ++x);
    }, []);

    const bookingContext = {
        initialStep,
        currentStep,
        ticketCategoryId,
        ticketCategory,
        selectedTickets,
        selectedBoltOns,
        date,
        before,
        after,
        allowedBoltOns,
        minDate,
        maxDate,
        time,
        price,
        priceBoltOns,
        g2BookingId,
        discountCode,
        singleUseDiscountAmount,
        happyToSitOpposite,
        inError,
        currentDate,
        contactDetails,
        paymentMethod,
        secondsRemaining,
        setCurrentDate,
        selectTicketCategoryId,
        selectTickets,
        setSelectedBoltOns,
        selectDate,
        selectTime,
        selectPrice,
        selectPriceBoltOns,
        setG2BookingId,
        setInitialStep,
        setDiscountCode,
        setSingleUseDiscountAmount,
        selectHappyToSitOpposite,
        totalBookingPrice,
        back,
        restart,
        next,
        setInError,
        complete,
        setContactDetails,
        setPaymentMethod,
        startTimer,
        cardTry,
        incrementCardTry,
    };

    return <Context.Provider value={bookingContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
