import { apiUrl } from '../config';
import axios from 'axios';

export const StatusCodeOK = 200;
export const StatusCodeBadRequest = 400;
export const StatusCodeUnauthorised = 401;
export const StatusCodeConflict = 409;
export const StatusCodeExpired = 410;

export const configureAxios = () => {
    // const INTERNAL_SERVER_ERROR = 500;
    // const GATEWAY_TIMEOUT = 504;
    // const OK = 200;

    axios.interceptors.request.use((config) => {
        config.headers['Content-Type'] = 'application/json';
        config.baseURL = apiUrl();
        config.withCredentials = true;
        //console.log(config);
        return config;
    });

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (!error.response) {
                console.error(error);
                return Promise.reject(error);
            }

            const { status } = error.response;

            console.error({ status, response: error.response });

            if (status === StatusCodeBadRequest || status === StatusCodeConflict) {
                return error.response;
            }

            if (status >= 500 && error?.config?.url?.includes('v1/ApplePay/')) {
                return error.response;
            }

            if (status === StatusCodeUnauthorised) {
                if (window.location.pathname !== '/login') {
                    window.location.href = '/login';
                }
                return Promise.reject(error);
            }
            if (status > 401 && status < 500) {
                window.location.href = '/400';
                return Promise.reject(error);
            }

            window.location.href = '/500';
            return Promise.reject(error);
        }
    );
};
