import React from 'react';
import { companies, companyDetailsGetV3, getCompanyIdV3 } from '../../config';

const Footer = () => {
    const detailsGet = () => {
        return companyDetailsGetV3();
    };

    if (!detailsGet()) {
        return null;
    }

    //LGP
    if (getCompanyIdV3().toUpperCase() === companies.LGP) {
        return (
            <div className="bg-gray-900">
                <div className="max-w-screen-md mx-auto p-4">
                    <div className="md:grid md:grid-cols-3 md:grid-rows-2 text-white">
                        <div className="col-start-1 row-start-1">
                            <div className="flex-1 min-w-0 mb-4">
                                <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="h-10 sm:h-20 object-contain pr-4" />
                            </div>
                        </div>
                        <div className="space-y-4 col-start-2 row-start-1 row-span-2 flex flex-col justify-end">
                            <div className="flex items-center">
                                <i className="fas fa-map-marker-alt fa-fw text-orange-500 mt-1"></i>
                                <span className="ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal text-white">
                                    {detailsGet()
                                        .address.split('|')
                                        .map((x, k) => (
                                            <p key={k}>{x}</p>
                                        ))}
                                </span>
                            </div>
                            <a href={`tel:${detailsGet().tel}`} className="flex items-center">
                                <i className="fas fa-phone-alt fa-fw text-orange-500 mt-1"></i>
                                <span className="ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal text-white">{detailsGet().tel}</span>
                            </a>
                            <a href={`mailto:${detailsGet().email}`} target="_blank" rel="noopener noreferrer" className="flex items-center">
                                <i className="fas fa-envelope fa-fw text-orange-500 mt-1"></i>
                                <span className="ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal text-white">{detailsGet().email}</span>
                            </a>
                        </div>
                        <div className="col-start-3 row-start-1 row-span-2 flex flex-col justify-end">
                            <div className="text-2xl mb-2 mt-4 sm:mt-0">Links</div>
                            <ul className="list-disc ml-6">
                                <li>
                                    <a href="https://www.letsgopunting.co.uk/faqs/" target="_blank" rel="noopener noreferrer">
                                        FAQ's
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.letsgopunting.co.uk/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                                        Terms and Conditions
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.letsgopunting.co.uk/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.letsgopunting.co.uk/cookie-policy/" target="_blank" rel="noopener noreferrer">
                                        Cookie Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.letsgopunting.co.uk/accessibility/" target="_blank" rel="noopener noreferrer">
                                        Accessibility
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-start-1 row-start-2">
                            <div className="flex gap-x-4 mt-4">
                                <a href="https://www.facebook.com/letsgopunting" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook fa-fw text-orange-500 mt-1"></i>
                                </a>
                                <a href="https://twitter.com/letsgopunting" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-twitter fa-fw text-orange-500 mt-1"></i>
                                </a>
                                <a href="https://www.youtube.com/channel/UCrzYVYEeQBQqKBGKXfi0akQ/featured" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-youtube fa-fw text-orange-500 mt-1"></i>
                                </a>
                                <a href="https://www.instagram.com/letsgopunting/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram fa-fw text-orange-500 mt-1"></i>
                                </a>
                                <a href="https://www.pinterest.co.uk/letsgopunting/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-pinterest fa-fw text-orange-500 mt-1"></i>
                                </a>
                            </div>
                            <div className="text-white mt-4">
                                <div className="whitespace-no-wrap">Let’s Go Punting</div>
                                <div className="whitespace-no-wrap">Copyright © {new Date().getFullYear()} | All Rights Reserved</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //Stride
    if (getCompanyIdV3().toUpperCase() === companies.STRIDE || getCompanyIdV3().toUpperCase() === companies.STRIDEPUNTING) {
        return (
            <div className="bg-gray-100">
                <div className="max-w-screen-md mx-auto p-4">
                    <div className="md:grid md:grid-cols-2 md:grid-rows-1 text-black font-extrabold">
                        <div className="space-y-4 col-start-1 row-start-1 flex flex-col justify-start">
                            <div className="text-lg sm:text-2xl tracking-tight sm:tracking-normal text-blue-900">get in touch</div>
                            <div className="flex items-center">
                                <span>
                                    {detailsGet()
                                        .address.split('|')
                                        .map((x, k) => (
                                            <p key={k}>{x}</p>
                                        ))}
                                </span>
                            </div>
                            <a href={`tel:${detailsGet().tel}`} className="flex items-center">
                                {detailsGet().tel}
                            </a>
                            <a href={`mailto:${detailsGet().email}`} target="_blank" rel="noopener noreferrer" className="flex items-center">
                                {detailsGet().email}
                            </a>
                        </div>
                        <div className="col-start-2 row-start-1 flex flex-col justify-start space-y-4">
                            <div className="text-lg sm:text-2xl tracking-tight sm:tracking-normal text-blue-900">site links</div>
                            <ul className="list-none">
                                <li>
                                    <a href="https://stridecambridge.com/contact/" target="_blank" rel="noopener noreferrer">
                                        contact us
                                    </a>
                                </li>
                                <li>
                                    <a href="https://stridecambridge.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                        privacy
                                    </a>
                                </li>
                                <li>
                                    <a href="https://stridecambridge.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                                        terms &amp; conditions
                                    </a>
                                </li>
                                <li>
                                    <a href="https://stridecambridge.com/faqs/" target="_blank" rel="noopener noreferrer">
                                        faqs
                                    </a>
                                </li>
                                <li>
                                    <a href="https://stridecambridge.com/work-for-us" target="_blank" rel="noopener noreferrer">
                                        work for us
                                    </a>
                                </li>
                                <li>
                                    <a href="https://stridecambridge.com/accessibility" target="_blank" rel="noopener noreferrer">
                                        accessibility
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-white mt-4 bg-orange-500 px-4 py-4 -mx-4 font-extrabold text-xs">
                        <div className="whitespace-no-wrap">Copyright © {new Date().getFullYear()} Stride Cambridge | All Rights Reserved</div>
                    </div>
                </div>
            </div>
        );
    }

    //TPC
    if (getCompanyIdV3().toUpperCase() === companies.TPC) {
        return (
            <div className="bg-black">
                <div className="max-w-4xl md:max-w-6xl xl:max-w-7xl mx-auto text-gray-300 py-6">
                    <div className="md:grid md:grid-cols-3 gap-x-4 p-4">
                        <div className="space-y-8">
                            <img src={detailsGet().logoFooter} title={detailsGet().name} alt={detailsGet().name} className="h-10 sm:h-12 object-contain pr-4" />
                            <p>The Traditional Punting Company are a fully licensed punt tour operator in Cambridge.</p>
                            <div className="flex gap-x-4 mt-4">
                                <a href="https://www.facebook.com/traditionalpuntingcompany/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f fa-fw mt-1 fa-2x tpc-link"></i>
                                </a>
                                <a href="https://www.instagram.com/traditional_punting_company" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram fa-fw mt-1 fa-2x tpc-link"></i>
                                </a>
                                <a href="https://twitter.com/traditionalpunt" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-twitter fa-fw mt-1 fa-2x tpc-link"></i>
                                </a>
                                <a href="https://www.pinterest.co.uk/puntingcambridge/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-pinterest-p fa-fw mt-1 fa-2x tpc-link"></i>
                                </a>
                                <a href="https://www.youtube.com/channel/UC1ekKyZNsW97fqtnAqMbDVg" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-youtube fa-fw mt-1 fa-2x tpc-link"></i>
                                </a>
                            </div>
                        </div>
                        <div className="mt-4 md:mt-0 col-start-3">
                            <div className="text-2xl font-semibold tpc-primary uppercase">Useful Info</div>
                            <ul className="list-none space-y-2 mt-3">
                                <li>
                                    <a href="https://traditionalpuntingcompany.com/contact/" target="_blank" rel="noopener noreferrer">
                                        Contact
                                    </a>
                                </li>
                                <li>
                                    <a href="https://traditionalpuntingcompany.com/faqs/" target="_blank" rel="noopener noreferrer">
                                        FAQ
                                    </a>
                                </li>
                                <li>
                                    <a href="https://traditionalpuntingcompany.com/find-us/" target="_blank" rel="noopener noreferrer">
                                        Find Us
                                    </a>
                                </li>
                                <li>
                                    <a href="https://traditionalpuntingcompany.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="https://traditionalpuntingcompany.com/refunds-cancellation-policy/" target="_blank" rel="noopener noreferrer">
                                        Refunds &amp; Cancellation Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="https://traditionalpuntingcompany.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
                                        Terms &amp; Conditions
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-900">
                    <div className="max-w-4xl md:max-w-6xl xl:max-w-7xl mx-auto text-white text-base px-4 py-5">
                        <div className="">© {new Date().getFullYear()} River Cam Punting Limited. Company number 09487736.</div>
                    </div>
                </div>
            </div>
        );
    }

    //Glide
    if (getCompanyIdV3().toUpperCase() === companies.GLIDE) {
        return (
            <div className="bg-glide-700">
                <div className="max-w-screen-md mx-auto text-white">
                    <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 sm:grid-row-2 md:grid-rows-1 items-center py-4 px-2">
                        <div className="space-y-1">
                            <img src={detailsGet().logoFooter} title={detailsGet().name} alt={detailsGet().name} className="h-10 sm:h-12 object-contain pr-4" />
                        </div>
                        <div>
                            <div>
                                <span className="fas fa-fw fa-phone-alt mr-2" />
                                <a href={`tel:${detailsGet().tel}`}>{detailsGet().tel}</a>
                            </div>
                            <div>
                                <span className="fas fa-fw fa-envelope mr-2" />
                                <a href={`mailto:${detailsGet().email}`}>{detailsGet().email}</a>
                            </div>
                        </div>
                        <div className="sm:col-start-2 sm:row-start-2 md:row-start-1 md:col-start-3">
                            <div>
                                <a href="https://info.glide-platform.co.uk/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>
                            </div>
                            <div>
                                <a href="https://info.glide-platform.co.uk/terms-conditions/" target="_blank" rel="noopener noreferrer">
                                    Terms &amp; Conditions
                                </a>
                            </div>
                            <div>© Light Blue Holdings Ltd {new Date().getFullYear()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //Hen
    if (getCompanyIdV3().toUpperCase() === companies.HEN) {
        return (
            <div className="bg-hen-500">
                <div className="max-w-screen-md mx-auto p-4">
                    <div className="text-white">
                        <div className="space-y-4 flex flex-col justify-end">
                            <div className="hen-title-font text-3xl">Get in touch</div>
                            <div>To book, discuss a package in more detail, or if you have any questions please contact our friendly team for more information.</div>
                            <a href={`mailto:${detailsGet().email}`} target="_blank" rel="noopener noreferrer" className="flex items-center font-semibold">
                                <span className="text-base sm:text-xl tracking-tight sm:tracking-normal">{detailsGet().email}</span>
                            </a>
                            <a href={`tel:${detailsGet().tel}`} className="flex items-center font-semibold">
                                Call us on: <span className="ml-1 text-base sm:text-xl tracking-tight sm:tracking-normal">{detailsGet().tel}</span>
                            </a>
                            <a href="https://www.cambridge-hen-party.co.uk/terms-conditions/" target="_blank" rel="noopener noreferrer" className="font-semibold">
                                T's &amp; C's
                            </a>
                            <div className="text-center">Copyright {new Date().getFullYear()} Cambridge Hen Party</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //Tyrrells
    if (getCompanyIdV3().toUpperCase() === companies.TYRRELLS) {
        return (
            <div className="bg-tyrrells-500 pt-20">
                <div className="max-w-screen-md mx-auto p-4 text-center md:text-left">
                    <div className="text-white">
                        <div className="md:flex">
                            <div className="space-y-2">
                                <a href="https://www.cambridgepunting.co/" className="p-1 flex justify-center mb-4">
                                    <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="h-16 md:h-20 w-auto" />
                                </a>

                                <div className="flex flex-col space-y-4 text-base font-medium md:pl-10">
                                    <a href="https://www.cambridgepunting.co/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                                        Terms &amp; Conditions
                                    </a>
                                    <a href="https://www.cambridgepunting.co/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                        Privacy Policy
                                    </a>
                                </div>
                            </div>
                            <div className="flex-1 hidden md:block"></div>
                            <div className="flex-1 space-y-4 mt-8 md:mt-0 text-sm">
                                <div className="pb-4">
                                    <span>
                                        <p>{detailsGet().name}</p>
                                        {detailsGet()
                                            .address.split('|')
                                            .map((x, k) => (
                                                <p key={k}>{x}</p>
                                            ))}
                                    </span>
                                </div>
                                <div className="text-lg font-semibold">
                                    <a href={`tel:${detailsGet().tel}`}>{detailsGet().tel}</a>
                                </div>
                                <div className="text-lg font-semibold pb-6">
                                    <a href="https://www.cambridgepunting.heartitdev6.co.uk/contact-us">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div className="my-8 flex justify-center text-sm font-medium">
                            <div>© Copyright Cambridge Punting {new Date().getFullYear()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //No fallback
    return null;
};

export default Footer;
