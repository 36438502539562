import moment from 'moment';
import 'moment/locale/en-gb';

export const formatDate = (date) => {
    return moment(date).format('Do MMM yyyy');
};

export const fixDate = (date) => {
    if (!date) {
        return null;
    }

    if (moment.isMoment(date)) {
        return date.format('YYYY-MM-DD');
    }

    const m = moment([date.getFullYear(), date.getMonth(), date.getDate()]);

    return m.format('YYYY-MM-DD');
};

export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const DaysDiff = (minDate, maxDate) => {
    if (minDate && maxDate) {
        const timeDiff = maxDate.getTime() - minDate.getTime();

        return timeDiff / (1000 * 3600 * 24);
    } else {
        return 365;
    }
};

export const MinTicketDate = (selectedTickets) => {
    let date = new Date();

    if (selectedTickets) {
        selectedTickets
            .filter((x) => x.amount > 0 && x.fromDate)
            .forEach((ticket) => {
                const newDate = new Date(ticket.fromDate);
                if (newDate > date) {
                    date = newDate;
                }
            });
    }

    return date;
};

export const MaxTicketDate = (selectedTickets) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    if (selectedTickets) {
        selectedTickets
            .filter((x) => x.amount > 0 && x.toDate)
            .forEach((ticket) => {
                const newDate = new Date(ticket.toDate);
                if (newDate < date) {
                    date = newDate;
                }
            });
    }

    return date;
};
