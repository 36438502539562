import React from 'react';
import 'react-calendar/dist/Calendar.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { BookingProvider } from './contexts/booking-context';
import './css/output.css';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <Router>
                <BookingProvider>
                    <App />
                </BookingProvider>
            </Router>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
