import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { apiWorldPayComplete, apiWorldPayLog, apiWorldPayStart } from '../../api/api-world-pay';
import { worldPayScriptUrl, getTourTypeIdV3, companyDetailsGetV3 } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPurchase } from '../../helpers/gtm';

const WorldPay = () => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const { g2BookingId, price, priceBoltOns, complete, ticketCategory, selectedTickets, selectedBoltOns, cardTry } = useContext(BookingContext);
    const wpRef = useRef();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = worldPayScriptUrl();
        script.addEventListener('load', () => setScriptLoaded(true));
        document.head.appendChild(script);
    }, []);

    const resultCallbackFunction = useCallback(
        async (result) => {
            await apiWorldPayLog(getTourTypeIdV3(), g2BookingId, result);

            switch (result?.order?.status) {
                case 'success':
                    await apiWorldPayComplete(getTourTypeIdV3(), g2BookingId);

                    trackPurchase(
                        g2BookingId, //
                        price,
                        priceBoltOns,
                        ticketCategory.ticketCategoryName,
                        selectedTickets,
                        selectedBoltOns
                    );

                    complete();
                    break;
                case 'failure':
                    setErrors([{ message: 'The payment has failed' }]);
                    break;
                case 'cancelled_by_customer':
                case 'cancelled_by_shopper':
                    setErrors([{ message: 'Payment cancelled' }]);
                    break;
                default:
                    setErrors([{ message: `There's been an error processing the payment. Please try another card or call us on ${companyDetailsGetV3().tel}` }]);
                    break;
            }
        },
        [complete, g2BookingId, price, priceBoltOns, selectedBoltOns, selectedTickets, ticketCategory.ticketCategoryName]
    );

    const worldPayStart = useCallback(async () => {
        const url = await apiWorldPayStart(getTourTypeIdV3(), g2BookingId, cardTry);

        var customOptions = {
            url: url,
            type: 'iframe',
            inject: 'immediate',
            target: 'worldpay-div',
            resultCallback: resultCallbackFunction,
        };

        var libraryObject = new window.WPCL.Library();
        libraryObject.setup(customOptions);

        wpRef.current = true;
    }, [g2BookingId, resultCallbackFunction, cardTry]);

    useEffect(() => {
        if (scriptLoaded) {
            worldPayStart();
        }

        return () => {
            if (wpRef.current) {
                var libraryObject = new window.WPCL.Library();
                libraryObject.destroy();
            }
        };
    }, [scriptLoaded, worldPayStart]);

    return (
        <>
            <div id="worldpay-div"></div>
            {!scriptLoaded && <div className="text-center">Loading...</div>}
            {errors && (
                <ul id="paymentsense-errors">
                    {errors.map((error, key) => (
                        <li key={key}>{error.message}</li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default WorldPay;
