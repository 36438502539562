import React, { useCallback, useContext, useEffect, useState } from 'react';
import { apiApplePayPay, apiApplePayVerifyMerchant } from '../../api/api-apple-pay';
import { apiPayViaSingleUseDiscountCode } from '../../api/api-booking';
import { StatusCodeOK } from '../../api/axios-helper';
import ccLogo from '../../assets/images/cc-logo.png';
import ppLogo from '../../assets/images/pp-logo.png';
import { getApplePayMerchantId, getCompanyIdV3, getTourTypeIdV3, hasApplePay, hasWorldPay } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPage } from '../../helpers/gtm';
import { FontAwesomeIcon } from '../common';
import ActionCard from '../common/action-card';
import PayPal from '../payment/paypal';
import WorldPay from '../payment/world-pay';

const StepComplete = () => {
    const [applePay, setApplePay] = useState(false);
    const { restart, paymentMethod, setPaymentMethod, totalBookingPrice, complete, g2BookingId, discountCode, incrementCardTry } = useContext(BookingContext);

    useEffect(() => {
        if (hasApplePay() && window.ApplePaySession && window.ApplePaySession.canMakePaymentsWithActiveCard(getApplePayMerchantId())) {
            setApplePay(true);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/step-payment');
    }, []);

    const applePayClick = useCallback(() => {
        var applePaySession = new window.ApplePaySession(6, {
            countryCode: 'GB',
            currencyCode: 'GBP',
            supportedNetworks: ['visa', 'masterCard', 'discover', 'amex'],
            merchantCapabilities: ['supports3DS'],
            total: { label: getTourTypeIdV3() === 1 ? 'Punting' : 'Walking', amount: totalBookingPrice() },
        });

        applePaySession.begin();

        applePaySession.onvalidatemerchant = async function (event) {
            const result = await apiApplePayVerifyMerchant(getCompanyIdV3(), event.validationURL);
            const merchantSession = JSON.parse(result.json);
            applePaySession.completeMerchantValidation(merchantSession);
        };

        applePaySession.onpaymentauthorized = async function (event) {
            const result = await apiApplePayPay(getTourTypeIdV3(), g2BookingId, getCompanyIdV3(), event.payment.token);

            try {
                if (result?.completedOk) {
                    applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                    complete();
                } else {
                    applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE);
                }
            } catch {
                window.location.href = '/500';
            }
        };
    }, [totalBookingPrice, g2BookingId, complete]);

    const payViaSingleUseDiscountCode = useCallback(async () => {
        const result = await apiPayViaSingleUseDiscountCode(getTourTypeIdV3(), g2BookingId, discountCode);

        if (result.status === StatusCodeOK) {
            complete();
        }
    }, [g2BookingId, discountCode, complete]);

    useEffect(() => {
        if (totalBookingPrice() === 0) {
            payViaSingleUseDiscountCode();
        }
    }, [totalBookingPrice, payViaSingleUseDiscountCode]);

    const Buttons = () => {
        return (
            <>
                <button type="button" onClick={restart} className="btn-secondary w-1/3">
                    Restart
                </button>
                <div className="w-2/3"></div>
            </>
        );
    };

    return (
        <ActionCard title="Please Select Your Payment Method">
            <div className="flex flex-col flex-grow">
                <div className="flex flex-col flex-grow">
                    <>
                        <div className="flex-none">
                            {applePay && (
                                <div className="m-8">
                                    <div className="apple-pay-button" onClick={applePayClick}></div>
                                </div>
                            )}
                            <div className={`m-3 space-x-4 ${paymentMethod ? 'hidden md:flex' : 'flex'}`}>
                                {totalBookingPrice() === 0 ? (
                                    <div>Nothing To Pay... Completing Booking.</div>
                                ) : (
                                    <>
                                        {hasWorldPay() && (
                                            <div
                                                className={`flex flex-col justify-center items-center flex-1 rounded px-8 py-4 cursor-pointer text-center relative ${paymentMethod === 'Card' ? 'shadow-outline' : 'shadow hover:shadow-outline'}`}
                                                onClick={() => {
                                                    if (!paymentMethod || paymentMethod === 'PayPal') {
                                                        incrementCardTry();
                                                    }
                                                    setPaymentMethod('Card');
                                                }}>
                                                {paymentMethod === 'Card' && <FontAwesomeIcon className="absolute top-1.5 right-0.5" icon="check-circle" />}
                                                <img src={ccLogo} alt="Credit Card" className="w-1/3" />
                                                <div className="pt-5 text-sm">Pay with Credit/Debit Card</div>
                                            </div>
                                        )}
                                        <div
                                            className={`flex flex-col justify-center items-center flex-1 rounded px-8 py-4 cursor-pointer text-center relative ${paymentMethod === 'PayPal' ? 'shadow-outline' : 'shadow hover:shadow-outline'}`}
                                            onClick={() => {
                                                setPaymentMethod('PayPal');
                                            }}>
                                            {paymentMethod === 'PayPal' && <FontAwesomeIcon className="absolute top-1.5 right-0.5" icon="check-circle" />}
                                            <div className="flex justify-center items-center flex-1">
                                                <img src={ppLogo} alt="PayPal" className="w-1/3" />
                                            </div>
                                            <div className="pt-5 text-sm">Pay with PayPal</div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {paymentMethod && (
                            <div className="flex flex-col flex-grow p-2">
                                {paymentMethod === 'Card' && <WorldPay></WorldPay>}
                                {paymentMethod === 'PayPal' && <PayPal></PayPal>}
                            </div>
                        )}
                    </>
                </div>
                <div className="button-bar">
                    <Buttons />
                </div>
            </div>
        </ActionCard>
    );
};

export default StepComplete;
