import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { apiPayPalOrderCapture, apiPayPalOrderCreate } from '../../api/api-paypal';
import { getPayPalClientIdV3, getTourTypeIdV3 } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPurchase } from '../../helpers/gtm';

const PayPal = () => {
    const [cancelled, setCancelled] = useState(false);
    const [error, setError] = useState(null);
    const { g2BookingId, complete, price, priceBoltOns, ticketCategory, selectedTickets, selectedBoltOns } = useContext(BookingContext);
    const paypalRef = useRef();

    const payPalButtons = useCallback(() => {
        window.paypal
            .Buttons({
                fundingSource: window.paypal.FUNDING.PAYPAL,
                createOrder: async () => {
                    setError(null);
                    const result = await apiPayPalOrderCreate(getTourTypeIdV3(), g2BookingId);
                    if (!result.id) {
                        return Promise.reject(result);
                    }
                    return result.id;
                },
                onCancel: async () => {
                    setCancelled(true);
                },
                onApprove: async (data, actions) => {
                    const result = await apiPayPalOrderCapture(getTourTypeIdV3(), g2BookingId, data.orderID);
                    if (!result.id) {
                        if (result.message === 'INSTRUMENT_DECLINED') {
                            // Recoverable state
                            return actions.restart();
                        }
                        return Promise.reject('Sorry, your transaction could not be processed.');
                    }

                    trackPurchase(
                        g2BookingId, //
                        price,
                        priceBoltOns,
                        ticketCategory.ticketCategoryName,
                        selectedTickets,
                        selectedBoltOns
                    );

                    // ReactGA.plugin.require('ecommerce');
                    // ReactGA.plugin.execute('ecommerce', 'addTransaction', {
                    //     id: g2BookingId,
                    //     revenue: ((price || 0) + (priceBoltOns || 0)).toFixed(2),
                    // });

                    // if (selectedTickets) {
                    //     selectedTickets
                    //         .filter((x) => x.amount > 0)
                    //         .forEach((item) => {
                    //             ReactGA.plugin.execute('ecommerce', 'addItem', {
                    //                 id: g2BookingId, // Transaction ID. Required.
                    //                 sku: item.ticketTypeName, // SKU needed if passing multiple items
                    //                 name: item.ticketTypeName, // Product name. Required.
                    //                 category: ticketCategory.ticketCategoryName, // Category or variation.
                    //                 price: item.priceEach.toFixed(2), // Unit price.
                    //                 quantity: item.amount.toString(), // Quantity.
                    //             });
                    //         });
                    // }

                    // if (selectedBoltOns) {
                    //     selectedBoltOns
                    //         .filter((x) => x.amount > 0)
                    //         .forEach((item) => {
                    //             ReactGA.plugin.execute('ecommerce', 'addItem', {
                    //                 id: g2BookingId, // Transaction ID. Required.
                    //                 sku: item.boltOnName, // SKU needed if passing multiple items
                    //                 name: item.boltOnName, // Product name. Required.
                    //                 category: 'Bolt-On', // Category or variation.
                    //                 price: item.priceEach.toFixed(2), // Unit price.
                    //                 quantity: item.amount.toString(), // Quantity.
                    //             });
                    //         });
                    // }
                    // ReactGA.plugin.execute('ecommerce', 'send');
                    // ReactGA.plugin.execute('ecommerce', 'clear');

                    complete();
                },
                onError: (err, actions) => {
                    setError(err);
                },
            })
            .render(paypalRef.current);
    }, [g2BookingId, complete, price, priceBoltOns, ticketCategory, selectedTickets, selectedBoltOns]);

    useEffect(() => {
        const clientId = getPayPalClientIdV3();
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=GBP`;
        script.addEventListener('load', () => payPalButtons());
        document.head.appendChild(script);
    }, [payPalButtons]);

    return (
        <div className="text-center">
            <div ref={paypalRef}></div>
            {cancelled && <div>Payment cancelled</div>}
            {error && (error.message ? <h2 className="text-red-700">{error.message}</h2> : <h2 className="text-red-700">A PayPal error occurred. Please try again</h2>)}
            {error && error.errors && (
                <ul className="list-none; text-red-700">
                    {error.errors.map((error, key) => (
                        <li key={key}>{error.errorMessage}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default PayPal;
