import axios from 'axios';

export const apiPayPalOrderCreate = async (tourTypeId, g2BookingId) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/PayPalOrderCreate',
        data: { tourTypeId, g2BookingId },
    });
    return result.data;
};

export const apiPayPalOrderCapture = async (tourTypeId, g2BookingId, orderId) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/PayPalOrderCapture',
        data: { tourTypeId, g2BookingId, orderId },
    });
    return result.data;
};
