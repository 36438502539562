import React, { useState } from 'react';

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const Help = ({ help }) => {
    const [open, setOpen] = useState(false);

    if (help) {
        return (
            <>
                <span className="cursor-pointer fad fa-lg fa-question-circle ml-2" onClick={() => setOpen(!open)}></span>
                {open && (
                    <div>
                        {help.split('#').map((part, key) =>
                            URL_REGEX.test(part) ? (
                                <a key={key} href={part} className="underline" target="_blank" rel="noopener noreferrer">
                                    {part}{' '}
                                </a>
                            ) : (
                                part
                            )
                        )}
                    </div>
                )}
            </>
        );
    }

    return null;
};

export default Help;
