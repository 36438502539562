import { Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { BookingContext } from '../../contexts/booking-context';
import { trackPage } from '../../helpers/gtm';
import { ActionCard, InputCheckbox } from '../common';

const StepCOVID = () => {
    const { happyToSitOpposite, selectHappyToSitOpposite, back } = useContext(BookingContext);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/step-covid');
    }, []);

    const Buttons = () => {
        return (
            <>
                <button type="button" onClick={back} className="btn-secondary w-1/3">
                    Back
                </button>
                <button type="submit" className="btn-primary w-2/3">
                    Next
                </button>
            </>
        );
    };

    return (
        <ActionCard title="COVID-19">
            <Formik
                initialValues={{ happy: happyToSitOpposite }}
                enableReinitialize
                onSubmit={async (values) => {
                    selectHappyToSitOpposite(values.happy);
                }}>
                {() => (
                    <Form className="flex flex-col flex-grow">
                        <div className="p-4">
                            <InputCheckbox name="happy" label="I'm Happy to Sit Opposite Another Group" />
                        </div>
                        <div className="p-4 pt-0 text-sm">
                            Social Distancing Guildelines stipulate that there should be a minimum of 1 metre between households/bubbles when outside. Our boats have sections where there are 2 rows for up to 3 people opposite each other with a
                            minimum head-to-head distance of 1.5 metres.
                        </div>
                        <div className="p-4 pt-0 text-sm">Please check the box above if you are happy to sit opposite another group.</div>
                        <div className="p-4 pt-0 text-sm flex-grow">
                            <span className="font-semibold">Please note:</span> there will be more tour times available if you opt to sit opposite another group.
                        </div>
                        <div className="button-bar">
                            <Buttons />
                        </div>
                    </Form>
                )}
            </Formik>
        </ActionCard>
    );
};

export default StepCOVID;
