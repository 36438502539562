import axios from 'axios';
import { companies } from '../config';
import { fixDate } from '../helpers/date-helpers';
import { StatusCodeOK, StatusCodeExpired, StatusCodeConflict } from './axios-helper';

export const apiAvailability = async (tourTypeId, ticketCategoryId, tickets, forMonth, forWeekStarting, forDay, happyToSitOpposite, trueOnly = false, before = null, after = null) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/Availability',
        data: { tourTypeId, ticketCategoryId, forMonth: fixDate(forMonth), forWeekStarting: fixDate(forWeekStarting), forDay: fixDate(forDay), tickets, happyToSitOpposite, before, after },
    });

    const dates = [];

    if (trueOnly) {
        for (const [key, value] of Object.entries(result.data)) {
            if (value) {
                dates.push(new Date(key));
            }
        }
    } else {
        for (const [key, value] of Object.entries(result.data)) {
            dates.push({ Date: new Date(key), available: value });
        }
    }

    return dates;
};

export const apiAvailableTimes = async (tourTypeId, ticketCategoryId, tickets, forDay, happyToSitOpposite, before, after) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/Availability',
        data: { tourTypeId, ticketCategoryId, forDay: fixDate(forDay), tickets, happyToSitOpposite, before, after },
    });

    const times = [];

    for (const [key, value] of Object.entries(result.data)) {
        const parts = key.split('T');

        times.push({ time: parts[1].substring(0, 5), available: value });
    }

    return times;
};

export const apiCreateInProgressBooking = async (tourTypeId, companyId, ticketCategoryId, tickets, date, time, happyToSitOpposite, discountCode, totalTicketPrice) => {
    if (!discountCode) {
        discountCode = '';
    }

    if (companyId === companies.STRIDEPUNTING) {
        companyId = companies.STRIDE;
    }

    const result = await axios({
        method: 'post',
        url: '/api/v1/BookingInProgress',
        data: { tourTypeId, companyId, ticketCategoryId, date: fixDate(date), time, discountCode, totalTicketPrice, happyToSitOpposite, tickets },
    });

    switch (result.status) {
        case StatusCodeOK:
            return { status: result.status, id: result.data };
        case StatusCodeConflict:
            return { status: result.status, id: null, error: 'Time no longer available' };
        default:
            return { status: result.status, id: null };
    }
};

export const apiAddBoltOnsToInProgressBooking = async (g2BookingId, tourTypeId, totalBoltOnPrice, boltOns) => {
    const result = await axios({
        method: 'patch',
        url: '/api/v1/BookingInProgress/BoltOns',
        data: { g2BookingId, tourTypeId, totalBoltOnPrice, boltOns },
    });

    switch (result.status) {
        case StatusCodeOK:
            return { status: result.status, id: result.data };
        case StatusCodeExpired:
            return { status: result.status, id: null, error: 'Booking expired' };
        default:
            return { status: result.status, id: null };
    }
};

export const apiBookingSummaryGet = async (tourTypeId, g2BookingId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/BookingSummary',
        params: { tourTypeId, g2BookingId },
    });

    return result.data;
};

export const apiBookingSummaryContactNumberValidator = async (contactNumber) => {
    if (!contactNumber) {
        //Not a required field
        return {
            formattedContactNumber: undefined,
            isValid: true,
        };
    }

    const result = await axios({
        method: 'post',
        url: '/api/v1/ContactNumberValidator',
        params: { contactNumber },
    });

    return result.data;
};

export const apiBookingRestart = async (tourTypeId, g2BookingId) => {
    try {
        var result = await axios({
            method: 'delete',
            url: '/api/v1/BookingInProgress',
            data: { tourTypeId, g2BookingId },
        });

        return result.data;
    } catch {
        //Ignore as system will clean up anyway
        return null;
    }
};

export const apiBookingSummarySave = async (tourTypeId, g2BookingId, name, email, contactNumber, notes) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/BookingSummary',
        params: { tourTypeId, g2BookingId, name, email, contactNumber, notes },
    });

    switch (result.status) {
        case StatusCodeOK:
            return { status: result.status, id: result.data };
        case StatusCodeExpired:
            return { status: result.status, id: null, error: 'Booking expired' };
        default:
            return { status: result.status, id: null };
    }
};

export const apiPayViaSingleUseDiscountCode = async (tourTypeId, g2BookingId, singleUseDiscountCode) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/ZeroToPay',
        params: { tourTypeId, g2BookingId, singleUseDiscountCode },
    });

    switch (result.status) {
        case StatusCodeOK:
            return { status: result.status, id: result.data };
        case StatusCodeExpired:
            return { status: result.status, id: null, error: 'Booking expired' };
        default:
            return { status: result.status, id: null };
    }
};
