import React from 'react';
import Summary from './summary';

const ActionCard = ({ title, bodyClassName = '', children }) => {
    return (
        <div className="card max-w-screen-sm booking-card flex flex-grow flex-col sm:my-12">
            <div className="booking-card-title">
                <h3 className="text-lg leading-6 font-extrabold text-center text-gray-50">{title}</h3>
            </div>
            <div className={`flex-grow flex flex-col ${bodyClassName}`}>
                <Summary />
                {children}
            </div>
        </div>
    );
};

export default ActionCard;
