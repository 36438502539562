import axios from 'axios';

export const apiAffiliateLogin = async (email, code) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/Affiliate/Login',
        data: { email, code },
    });
    return result.data;
};

export const apiAffiliateLogout = async () => {
    await axios({
        method: 'post',
        url: '/api/v1/Affiliate/Logout',
    });

    window.location = '/';
};
