import React, { useContext, useEffect, useState } from 'react';
import { apiTicketCategoriesGet } from '../../api/api-reference-data';
import { companies, getCompanyIdV3, getTourTypeIdV3 } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPage } from '../../helpers/gtm';
import { ActionCard, Table, TableCell, TableRow } from '../common';

const StepTicketType = () => {
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const { selectTicketCategoryId, setInitialStep } = useContext(BookingContext);

    const companyId = () => {
        const companyId = getCompanyIdV3();
        if (companyId === companies.STRIDEPUNTING) {
            return companies.STRIDE;
        }
        return companyId;
    };

    useEffect(() => {
        const LoadData = async () => {
            const categories = (await apiTicketCategoriesGet(getTourTypeIdV3())).filter((x) => x.companyIds.includes(companyId()));

            if (categories.length === 1) {
                setInitialStep(2);
                selectTicketCategoryId(categories[0].id);
                return;
            }

            setData(categories);

            setLoading(false);
        };

        LoadData();
    }, [selectTicketCategoryId, setInitialStep]);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/step-ticket-type');
    }, []);

    return (
        <ActionCard title="Please Select Your Tickets">
            {!loading && (
                <>
                    <Table>
                        <tbody>
                            {data
                                .filter((x) => !x.isDisabled)
                                .map((item, key) => (
                                    <TableRow key={key} rowNum={key}>
                                        <TableCell text={item.ticketCategoryName} className="cursor-pointer" onClick={() => selectTicketCategoryId(item.id)} />
                                    </TableRow>
                                ))}
                        </tbody>
                    </Table>
                </>
            )}
        </ActionCard>
    );
};

export default StepTicketType;
