import React, { useContext } from 'react';
import { Table, TableRow, TableCell } from '.';
import { BookingContext } from '../../contexts/booking-context';
import moment from 'moment';
import { getTourTypeIdV3 } from '../../config';

export default function Summary() {
    const { currentStep, ticketCategory, selectedTickets, date, time, currentDate, selectedBoltOns, discountCode, singleUseDiscountAmount, price, priceBoltOns, contactDetails, secondsRemaining } = useContext(BookingContext);

    const timeRemaining = () => {
        const minutes = Math.floor(secondsRemaining / 60);
        const seconds = secondsRemaining - minutes * 60;

        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const calculateTotal = () => {
        const total = price + (priceBoltOns || 0) - (singleUseDiscountAmount || 0);
        if (total < 0) {
            return 0;
        }
        return total;
    };

    return (
        <div className="summary-outer">
            <Table auto>
                <tbody>
                    <TableRow>
                        <TableCell className="w-auto font-extrabold" slim>
                            Tour:
                        </TableCell>
                        <TableCell className="w-full font-extrabold" slim>
                            {getTourTypeIdV3() === 1 ? 'Punting' : 'Walking'}
                        </TableCell>
                    </TableRow>
                    {currentStep > 1 && (
                        <TableRow>
                            <TableCell className="w-auto" slim>
                                Type:
                            </TableCell>
                            <TableCell className="w-full" slim>
                                {ticketCategory.ticketCategoryName}
                            </TableCell>
                        </TableRow>
                    )}
                    {currentStep > 2 && selectedTickets && (
                        <TableRow>
                            <TableCell className="w-auto align-top whitespace-no-wrap" slim>
                                Group Size:
                            </TableCell>
                            <TableCell className="w-full" slim>
                                {selectedTickets
                                    .filter((x) => x.amount > 0)
                                    .map((item, key) => (
                                        <span key={key}>
                                            {item.amount}x {item.ticketTypeName}
                                            <br />
                                        </span>
                                    ))}
                            </TableCell>
                        </TableRow>
                    )}
                    {currentStep > 3 && currentStep !== 19 && (
                        <TableRow>
                            <TableCell className="w-auto whitespace-no-wrap" slim>
                                Booking Date:
                            </TableCell>
                            <TableCell className="w-full" slim>
                                {moment(currentDate || date).format('ddd Do MMM YYYY')}
                                {currentStep > 4 && <span> @ {time}</span>}
                            </TableCell>
                        </TableRow>
                    )}
                    {currentStep > 3 && discountCode && (
                        <TableRow>
                            <TableCell className="w-auto font-extrabold" slim>
                                Promo Code:
                            </TableCell>
                            <TableCell className="w-full font-extrabold" slim>
                                {discountCode} {singleUseDiscountAmount ? `(£${singleUseDiscountAmount})` : ''}
                            </TableCell>
                        </TableRow>
                    )}
                    {currentStep > 5 && selectedBoltOns && selectedBoltOns.filter((x) => x.amount).length > 0 && (
                        <TableRow>
                            <TableCell className="w-auto align-top" slim>
                                Extras:
                            </TableCell>
                            <TableCell className="w-full" slim>
                                {selectedBoltOns
                                    .filter((x) => x.amount > 0)
                                    .map((item, key) => (
                                        <span key={key}>
                                            {item.amount}x {item.boltOnName}
                                            <br />
                                        </span>
                                    ))}
                            </TableCell>
                        </TableRow>
                    )}
                    {currentStep > 4 && currentStep !== 19 && (
                        <>
                            <TableRow>
                                <TableCell className="w-auto align-top" slim>
                                    Total:
                                </TableCell>
                                <TableCell className="w-full" slim>
                                    £{((price || 0) + (priceBoltOns || 0)).toFixed(2)}
                                    {(priceBoltOns || 0) > 0 && (
                                        <span className="ml-4 hidden sm:inline">
                                            (Tickets: £{price.toFixed(2)}, Extras: £{priceBoltOns.toFixed(2)})
                                        </span>
                                    )}
                                </TableCell>
                            </TableRow>
                            {singleUseDiscountAmount && (
                                <>
                                    <TableRow>
                                        <TableCell className="w-auto align-top whitespace-no-wrap" slim>
                                            Promo:
                                        </TableCell>
                                        <TableCell className="w-full" slim>
                                            -£{singleUseDiscountAmount.toFixed(2)}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell className="w-auto align-top whitespace-no-wrap font-extrabold" slim>
                                            TOTAL TO PAY:
                                        </TableCell>
                                        <TableCell className="w-full font-extrabold" slim>
                                            £{calculateTotal().toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </>
                    )}
                    {currentStep > 6 && contactDetails && (
                        <>
                            <TableRow>
                                <TableCell className="w-auto" slim>
                                    Name:
                                </TableCell>
                                <TableCell className="w-full" slim>
                                    {contactDetails.name}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="w-auto" slim>
                                    Email:
                                </TableCell>
                                <TableCell className="w-full" slim>
                                    {contactDetails.email}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="w-auto" slim>
                                    Mobile:
                                </TableCell>
                                <TableCell className="w-full" slim>
                                    {contactDetails.mobile}
                                </TableCell>
                            </TableRow>
                        </>
                    )}
                    {currentStep > 4 && secondsRemaining > 0 && (
                        <TableRow>
                            <TableCell className="w-auto text-red-500" slim colSpan="2" right>
                                Time Remaining: <span className="font-mono font-semibold">{timeRemaining()}</span>
                            </TableCell>
                        </TableRow>
                    )}
                </tbody>
            </Table>
        </div>
    );
}
