import { useField } from 'formik';
import React from 'react';

const InputCheckbox = ({ label, className, horizontal = true, hideLabel = false, children, ...props }) => {
    const [field, meta] = useField(props);
    const vertical = () => !horizontal;

    return (
        <div>
            {!hideLabel && vertical() && <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">{children || label}</div>}
            <div className={`flex items-center ${className}`}>
                <input id={field.name} type="checkbox" className="form-checkbox ml-2 my-1 h-6 w-6 text-blue-600 transition duration-150 ease-in-out" checked={field.value} onChange={field.onChange} />
                {!hideLabel && horizontal && (
                    <label htmlFor={field.name} className="ml-2 block text-sm font-semibold leading-5 text-gray-900">
                        {children || label}
                    </label>
                )}
            </div>
            {meta.error && <div className="validation-error text-left">{meta.error}</div>}
        </div>
    );
};

export default InputCheckbox;
