import axios from 'axios';
import { companies } from '../config';

export const apiApplePayVerifyMerchant = async (companyId, appleUrl) => {
    const domain = window.location.hostname;

    if (companyId === companies.STRIDEPUNTING) {
        companyId = companies.STRIDE;
    }

    var count = 1;
    while (count <= 5) {
        try {
            const result = await axios({
                method: 'post',
                url: '/api/v1/ApplePay/MerchantVerify',
                data: { companyId, appleUrl, domain },
            });

            if (result.status === 500) {
                ++count;
            } else {
                return result.data;
            }
        } catch {
            ++count;
        }
    }

    window.location.href = '/500';
};

export const apiApplePayPay = async (tourTypeId, g2BookingId, companyId, applePaymentToken) => {
    const domain = window.location.hostname;
    let baseUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.hostname === 'localhost') {
        baseUrl += `:${window.location.port}`;
    }

    if (companyId === companies.STRIDEPUNTING) {
        companyId = companies.STRIDE;
    }

    var count = 1;
    while (count <= 5) {
        try {
            const result = await axios({
                method: 'post',
                url: '/api/v1/ApplePay/Pay',
                data: { companyId, tourTypeId, applePaymentToken, domain, g2BookingId, baseUrl },
            });

            if (result.status === 500) {
                ++count;
            } else {
                return result.data;
            }
        } catch {
            ++count;
        }
    }

    window.location.href = '/500';
};
