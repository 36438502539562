import React from 'react';

const ErrorDisplay = ({ errors, name, className = '', align = 'left' }) => {
    if (!errors) {
        return null;
    }

    if (typeof errors === 'string') {
        return <div className={`validation-error text-${align} ${className}`}>{errors}</div>;
    }

    if (name && errors.hasOwnProperty(name) && errors[name]) {
        return <div className={`validation-error text-${align} ${className}`}>{errors[name]}</div>;
    }

    return null;
};

export default ErrorDisplay;
