import axios from 'axios';

export const apiBoltOnsGetAvailabile = async (tourTypeId, g2BookingId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/BoltOns/Available',
        params: { tourTypeId, g2BookingId },
    });

    return result.data;
};
