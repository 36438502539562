import 'moment/locale/en-gb';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { apiAvailability } from '../../api/api-booking';
import { getTourTypeIdV3 } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPage } from '../../helpers/gtm';
import ActionCard from '../common/action-card';

const StepDate = () => {
    const { date, before, after, minDate, maxDate, selectedTickets, ticketCategoryId, selectDate, back, happyToSitOpposite } = useContext(BookingContext);
    const [availableDates, setAvailableDates] = useState(undefined);
    const [viewDate, setViewDate] = useState(undefined);

    const LoadData = useCallback(async () => {
        if (viewDate) {
            setAvailableDates(await apiAvailability(getTourTypeIdV3(), ticketCategoryId, selectedTickets, viewDate, null, null, happyToSitOpposite, true, before, after));
        }
    }, [viewDate, selectedTickets, ticketCategoryId, happyToSitOpposite, before, after]);

    useEffect(() => {
        setAvailableDates(undefined);
        LoadData();
    }, [LoadData]);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/step-date');
    }, []);

    const thisWeek = () => {
        const now = new Date();
        selectDate(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
    };

    const Buttons = () => {
        return (
            <>
                <button type="button" onClick={back} className="btn-secondary w-1/3">
                    Back
                </button>
                <div className="w-2/3"></div>
            </>
        );
    };

    const viewChanged = ({ activeStartDate, view }) => {
        if (view === 'month') {
            setViewDate(activeStartDate);
        }
    };

    const isAvailable = useCallback((availableDates, date) => {
        return availableDates.some((x) => x.getFullYear() === date.getFullYear() && x.getMonth() === date.getMonth() && x.getDate() === date.getDate());
    }, []);

    const tileDisabled = useCallback(
        ({ date, view }) => {
            if (view === 'month') {
                if (!availableDates) {
                    return true;
                }

                return !isAvailable(availableDates, date);
            }
        },
        [availableDates, isAvailable]
    );

    return (
        <ActionCard title="Please Select Your Date" bodyClassName="rdtFullPage py-1">
            <div className="flex flex-col flex-grow">
                <div className="py-1 sm:py-2 px-2">
                    <button type="button" onClick={thisWeek} className="btn-primary w-full">
                        Book for the Next 7 Days
                    </button>
                </div>
                <div className="flex-grow flex flex-col justify-between">
                    <Calendar
                        value={date}
                        onChange={selectDate}
                        locale="en-GB"
                        className="inline"
                        minDate={minDate}
                        maxDate={maxDate}
                        tileDisabled={tileDisabled}
                        defaultView="year"
                        showNeighboringMonth={false}
                        onActiveStartDateChange={viewChanged}
                        onViewChange={viewChanged}
                    />
                    <div className="button-bar -mb-1">
                        <Buttons />
                    </div>
                </div>
            </div>
        </ActionCard>
    );
};

export default StepDate;
