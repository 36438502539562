import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import lgpLogo from './assets/images/LGP-Logo.png';
import tpcLogo from './assets/images/TPC-Logo.png';
import tpcLogoFooter from './assets/images/TPC-Logo-White.png';
import strideLogo from './assets/images/STRIDE-Logo.png';
import glideLogo from './assets/images/GLIDE-Logo.png';
import glideLogoFooter from './assets/images/GLIDE-Logo-White.png';
import henLogo from './assets/images/Hen-Logo.jpg';
import tyrrellsLogo from './assets/images/Tyrrells-Logo.png';

const localIP = '192.168.68.113';
const localDoamin = 'rai-blah-local.net';

export const environments = {
    Localhost: 'Localhost',
    Development: 'Development',
    Test: 'Test',
    Production: 'Production',
};

export const companies = {
    LGP: 'LGP',
    TPC: 'TPC',
    STRIDE: 'STRIDE',
    STRIDEPUNTING: 'STRIDEPUNTING',
    GLIDE: 'GLIDE',
    HEN: 'HEN',
    TYRRELLS: 'TYRRELLS',
};

export const steps = {
    StepTicketType: 1,
    StepTickets: 2,
    StepCOVID: 19,
    StepDate: 3,
    StepTime: 4,
    StepBoltOns: 5,
    StepContactDetails: 6,
    StepPayment: 7,
    StepComplete: 20,
};

export const tourTypes = [
    { id: 1, name: 'Punting' },
    { id: 2, name: 'Walking' },
];

const authAffiliateCookieName = () => {
    const environment = getEnvironment().replace('Production', '').toLowerCase();
    return `api${environment && `.${environment}`}.affiliatecookie`;
};

export const getAffiliate = () => {
    const affiliateJwt = Cookies.get(authAffiliateCookieName());
    if (affiliateJwt) {
        var decoded = jwt.decode(affiliateJwt);
        if (decoded) {
            return {
                id: parseInt(decoded.AffiliateId),
                affiliateName: decoded.AffiliateName,
                companyId: decoded.CompanyId,
                logo: decoded.Logo,
            };
        }
    }
    return null;
};

export const worldPayScriptUrl = () => {
    return 'https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js';
};

export const checkoutScriptUrl = () => {
    return 'https://cdn.checkout.com/js/framesv2.min.js';
};

export const paymentSenseScriptUrl = () => {
    switch (getEnvironment()) {
        case environments.Production:
            return 'https://web.e.connect.paymentsense.cloud/assets/js/client.js';
        default:
            return 'https://web.e.test.connect.paymentsense.cloud/assets/js/client.js';
    }
};

export const apiUrl = () => {
    switch (getEnvironment()) {
        case environments.Localhost: //No override required, proxy in package.json will deal
            return undefined;
        case environments.Production:
            return 'https://api2.glide-platform.co.uk';
        case environments.Test:
            return 'https://api2.test.glide-platform.co.uk';
        case environments.Development:
        default:
            return 'https://api2.dev.glide-platform.co.uk';
    }
};

export const getEnvironment = () => {
    const hostname = window && window.location && window.location.hostname;

    switch (hostname) {
        case 'localhost':
        case localDoamin:
        case localIP:
            return environments.Localhost;
        case 'booking.traditionalpuntingcompany.com':
        case 'booking.stridecambridge.com':
        case 'punting.stridecambridge.com':
        case 'booking.letsgopunting.co.uk':
        case 'booking.cambridge-hen-party.co.uk':
        case 'booking.cambridgepunting.co':
        case 'affiliates.glide-platform.co.uk':
            return environments.Production;
        case 'booking.test.traditionalpuntingcompany.com':
        case 'booking.test.stridecambridge.com':
        case 'punting.test.stridecambridge.com':
        case 'booking.test.letsgopunting.co.uk':
        case 'booking.test.cambridge-hen-party.co.uk':
        case 'booking.test.cambridgepunting.co':
        case 'affiliates.test.glide-platform.co.uk':
            return environments.Test;
        default:
            return environments.Development;
    }
};

export const isAffiliate = () => {
    return window.location.hostname.startsWith('affiliates.') || window.location.href.includes('localhost:3025') || window.location.href.includes(`${localDoamin}:3025`);
};
export const isLGP = () => {
    return window.location.hostname.includes('letsgopunting.co.uk') || window.location.href.includes('localhost:3020') || window.location.href.includes(`${localIP}:3020`) || window.location.href.includes(`${localDoamin}:3020`);
};
export const isTPC = () => {
    return window.location.hostname.includes('traditionalpuntingcompany.com') || window.location.href.includes('localhost:3021') || window.location.href.includes(`${localIP}:3021`) || window.location.href.includes(`${localDoamin}:3021`);
};
export const isStride = () => {
    return (window.location.hostname.includes('stridecambridge.com') && window.location.hostname.includes('booking.')) || window.location.href.includes('localhost:3026') || window.location.href.includes(`${localDoamin}:3026`);
};
export const isStridePunting = () => {
    return (window.location.hostname.includes('stridecambridge.com') && window.location.hostname.includes('punting.')) || window.location.href.includes('localhost:3027') || window.location.href.includes(`${localDoamin}:3027`);
};
export const isHen = () => {
    return window.location.hostname.includes('cambridge-hen-party.co.uk') || window.location.href.includes('localhost:3028') || window.location.href.includes(`${localDoamin}:3028`);
};
export const isTyrrells = () => {
    return window.location.hostname.includes('cambridgepunting.co') || window.location.href.includes('localhost:3029') || window.location.href.includes(`${localDoamin}:3029`);
};

export const getGAV4 = () => {
    const companyId = getCompanyIdV3();

    switch (getEnvironment()) {
        // PRODUCTION
        case environments.Production:
            switch (companyId) {
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                    return '!!!';
                case companies.HEN:
                    return '!!!';
                case companies.GLIDE:
                    return '!!!';
                case companies.TPC:
                    return 'GTM-W6D2ZHW';
                case companies.TYRRELLS:
                    return '!!!';
                case companies.LGP:
                default:
                    return 'GTM-THTNSK9';
            }
        // TEST
        case environments.Test:
            switch (companyId) {
                case companies.GLIDE:
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                case companies.HEN:
                    return '!!!';
                case companies.TPC:
                    return 'GTM-TQ36C93';
                case companies.TYRRELLS:
                    return '!!!';
                case companies.LGP:
                default:
                    return 'GTM-WT83VZ8';
            }
        //DEV / LOCAL HOST
        default:
            switch (companyId) {
                case companies.GLIDE:
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                case companies.HEN:
                    return '!!!';
                case companies.TPC:
                    return 'GTM-P83CJC3';
                case companies.TYRRELLS:
                    return '!!!';
                case companies.LGP:
                default:
                    return 'GTM-T8WTPZM';
            }
    }
};

export const getGAV3 = () => {
    const companyId = getCompanyIdV3();

    switch (getEnvironment()) {
        // PRODUCTION
        case environments.Production:
            switch (companyId) {
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                    return 'UA-19450757-3';
                case companies.HEN:
                    return 'UA-10680272-3';
                case companies.GLIDE:
                    return 'UA-194250757-4';
                case companies.TPC:
                    return 'UA-73537470-1';
                case companies.TYRRELLS:
                    return 'UA-195325523-1';
                case companies.LGP:
                default:
                    return 'UA-342633-48';
            }
        // TEST
        case environments.Test:
            switch (companyId) {
                case companies.GLIDE:
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                case companies.HEN:
                    return 'UA-192906853-6';
                case companies.TPC:
                    return 'UA-192906853-3';
                case companies.TYRRELLS:
                    return 'UA-192906853-8';
                case companies.LGP:
                default:
                    return 'UA-192906853-4';
            }
        //DEV / LOCAL HOST
        default:
            switch (companyId) {
                case companies.GLIDE:
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                case companies.HEN:
                    return 'UA-192906853-5';
                case companies.TPC:
                    return 'UA-192906853-2';
                case companies.TYRRELLS:
                    return 'UA-192906853-7';
                case companies.LGP:
                default:
                    return 'UA-192906853-1';
            }
    }
};

export const getCheckoutDebug = () => {
    switch (getEnvironment()) {
        case environments.Production:
            return false;
        default:
            return true;
    }
};

export const getCheckoutPublicKey = () => {
    const companyId = getCompanyIdV3();

    switch (getEnvironment()) {
        case environments.Production:
            switch (companyId) {
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                    return 'pk_4194815a-ae94-44fe-86e6-72ecd783a65a';
                case companies.TPC:
                    return 'pk_0603d9c6-c570-4a5a-94ab-207870cfb09d';
                case companies.TYRRELLS:
                    return 'pk_e74674ea-2881-4a7f-ab01-809f4a20510e';
                default:
                    return 'pk_d6903ae1-f623-4892-8897-9f813857f15f';
            }
        case environments.Test:
            switch (companyId) {
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                    return 'pk_sbox_ssovlfscuqnpnand6fxrdm6wiu4';
                case companies.TPC:
                    return 'pk_sbox_4k5rjieiui5g76mzv47dxnjcja*';
                case companies.TYRRELLS:
                    return 'pk_sbox_afuo453axiwpns4b4gs2wola5yu';
                default:
                    return 'pk_sbox_3mujynztxjqyzye2x3nmo6kmcet';
            }
        default: //Dev
            switch (companyId) {
                case companies.STRIDE:
                case companies.STRIDEPUNTING:
                    return 'pk_sbox_b4fnzauq3tllov4mh3phcl535mx';
                case companies.TPC:
                    return 'pk_sbox_a4jwrn2dig6nx6bjs4jfx4daa43';
                case companies.TYRRELLS:
                    return 'pk_sbox_hjeb4uc7k5fh55akvvj3ylqrmub';
                default:
                    return 'pk_sbox_oxpuunyc6nmlvslwagxczjohei6';
            }
    }
};

export const getApplePayMerchantId = () => {
    switch (getEnvironment()) {
        case environments.Production:
            return 'merchant.com.lightblue';
        case environments.Test:
            return 'merchant.com.lightblue.sandboxtest';
        default:
            return 'merchant.com.lightblue.sandbox';
    }
};

export const getPayPalClientIdV3 = () => {
    const companyId = getCompanyIdV3();

    switch (getEnvironment()) {
        // PRODUCTION
        case environments.Production:
            //Stride
            if (companyId === companies.STRIDE || companyId === companies.STRIDEPUNTING) {
                return 'Ab4VI37IqWzdJOJNUZbvHuqPCiVV-jMPQ5Al9t5wFqJcu9piKyjix_NNJ7tKxcO_sGvaXslqJisJc6kd';
            }
            //TPC
            if (companyId === companies.TPC) {
                return 'ATnfponqg6FEkdWb0ps2qKhVgB3G63qakSyiEs6iFg7ZoYSSRHPoAWwxClIZPOYhU9fBa58Qq4DFHP_o';
            }
            if (companyId === companies.TYRRELLS) {
                return 'ASHObNrZvWsJjWgeqpHqe9yooNDAiB1ngm6Si9MgtQapXeqRn42wptgCEL45zpWC6GuISlwUMWMeFA4X';
            }
            //LPG
            return 'AXCJKbzSRhHejP5gxdnf58tNRW_xtmOPzhjOAkYsXd8L33SR3bhdwE_FEW5aApI60ofKyDbCIOulZyzr';
        // TEST
        case environments.Test:
            //Stride
            if (companyId === companies.STRIDE || companyId === companies.STRIDEPUNTING) {
                return 'AbqqifK1LoJ9-4E3cmPtYZMZ00uZEWeJn38qxTeEvbCtxeC_zkURcn8rZ5MsmgaMZULDhrx_qO2R2fgI';
            }
            if (companyId === companies.TPC) {
                return 'ATtAX9DQQoXc5Ajx2mpvoc-xbt7hWyIpYNzIj0lAj1PcXSAuJ7QkZFTM3xK_lDqK33uRFqpC3wTZJ8wn';
            }
            if (companyId === companies.TYRRELLS) {
                return 'AQqtk7EGneiRJQ60lyWvFCmf5CXcLVsJkxk5ZQQmO8KX7gmOKnFpnMd2MW4v5IT1DISDOajBKqvjvyhT';
            }
            //LPG
            return 'AbqqifK1LoJ9-4E3cmPtYZMZ00uZEWeJn38qxTeEvbCtxeC_zkURcn8rZ5MsmgaMZULDhrx_qO2R2fgI';
        //DEV / LOCAL HOST
        default:
            //Stride
            if (companyId === companies.STRIDE || companyId === companies.STRIDEPUNTING) {
                return 'AYh0ZXFGVuwTVDHquxXB6B0_uX6oBEJWh0_qZXOv0u1cE-_YdVlMo-0w_fQDdgYG35LPHo5D3eo3PEEz';
            }
            if (companyId === companies.TPC) {
                return 'AUg3Amzq6bH7308shkXRXoUX7i5Nl_ntiu3a4Ji8ouAEd7FvzUbC3BhHMc8jUXiu6h_EXSjJTIuHy8xK';
            }
            if (companyId === companies.TYRRELLS) {
                return 'AQqtk7EGneiRJQ60lyWvFCmf5CXcLVsJkxk5ZQQmO8KX7gmOKnFpnMd2MW4v5IT1DISDOajBKqvjvyhT';
            }
            return 'AYh0ZXFGVuwTVDHquxXB6B0_uX6oBEJWh0_qZXOv0u1cE-_YdVlMo-0w_fQDdgYG35LPHo5D3eo3PEEz';
    }
};

export const hasWorldPay = () => {
    const companyId = getCompanyIdV3();

    switch (companyId) {
        case companies.LGP:
        case companies.TPC:
            return true;
        default:
            return false;
    }
};

export const hasApplePay = () => {
    //This should now be done via WorldPay
    return false;

    // const companyId = getCompanyIdV3();

    // switch (companyId) {
    //     case companies.GLIDE:
    //         return false;
    //     default:
    //         return true;
    // }
};

export const logoUrl = () => {
    const affiliate = getAffiliate();

    if (affiliate) {
        return affiliate.logo;
    }

    return null;
};

export const getCompanyIdV3 = () => {
    const affiliate = getAffiliate();

    if (affiliate) {
        return affiliate.companyId;
    }

    if (isLGP()) {
        return companies.LGP;
    }

    if (isTPC()) {
        return companies.TPC;
    }

    if (isStride()) {
        return companies.STRIDE;
    }

    if (isStridePunting()) {
        return companies.STRIDEPUNTING;
    }

    if (isHen()) {
        return companies.HEN;
    }

    if (isTyrrells()) {
        return companies.TYRRELLS;
    }

    return companies.TPC;
};

export const getTourTypeIdV3 = () => {
    const companyDetails = companyDetailsGetV3();

    const savedTourTypeId = parseInt(localStorage.getItem('BookingTourTypeId') || companyDetails?.defaultTourTypeId || 1);

    if (companyDetails.tourTypeIds.includes(savedTourTypeId)) {
        return savedTourTypeId;
    }

    //If not allowed then remove saved and return default
    localStorage.removeItem('BookingTourTypeId');
    return companyDetailsGetV3()?.defaultTourTypeId || 1;
};

export const setTourTypeIdV3 = (tourTypeId) => {
    const companyDetails = companyDetailsGetV3();

    if (companyDetails.tourTypeIds.includes(tourTypeId)) {
        localStorage.setItem('BookingTourTypeId', tourTypeId);
    } else {
        localStorage.removeItem('BookingTourTypeId');
    }
    window.location.href = '/';
};

export const companyDetailsGetV3 = () => {
    switch (getCompanyIdV3()) {
        case companies.LGP:
            return {
                name: "Let's Go Punting",
                logo: lgpLogo,
                logoFooter: lgpLogo,
                tel: '01223 651 659',
                findUs: 'https://www.letsgopunting.co.uk/how-to-find-us/',
                email: 'info@letsgopunting.co.uk',
                termsAndConditions: 'https://www.letsgopunting.co.uk/terms-conditions/',
                address: 'Landing Stage,| Thompsons Ln,| Cambridge CB5 8AG',
                completionUrl: 'https://letsgopunting.co.uk/thank-you/',
                siteTitle: "Punting in Cambridge - Let's Go Punting - Cambridge Punting Tours",
                defaultTourTypeId: 1,
                tourTypeIds: [1],
            };
        case companies.TPC:
            return {
                name: 'Traditional Punting Company',
                logo: tpcLogo,
                logoFooter: tpcLogoFooter,
                tel: '01223 782306',
                findUs: 'https://www.traditionalpuntingcompany.com/find-us',
                email: 'info@traditionalpuntingcompany.com',
                termsAndConditions: 'https://www.traditionalpuntingcompany.com/terms-conditions/',
                address: 'Landing Stage,| Thompsons Ln,| Cambridge CB5 8AQ|United Kingdom (UK)',
                completionUrl: 'https://www.traditionalpuntingcompany.com/thank-you',
                completionSendAmount: true,
                siteTitle: 'Punting Cambridge - Book Punt Tours | Traditional Punting Co',
                defaultTourTypeId: 1,
                tourTypeIds: [1],
            };
        case companies.STRIDE:
            return {
                name: 'Stride',
                logo: strideLogo,
                logoFooter: strideLogo,
                tel: '01223 953 582',
                findUs: 'https://stridecambridge.com/how-to-find-us/',
                email: 'info@stridecambridge.com',
                termsAndConditions: 'https://stridecambridge.com/terms-and-conditions/',
                address: '30 Milton Road,| Cambridge,| CB4 1JY.',
                completionUrl: 'https://www.stridecambridge.com/thankyou',
                siteTitle: 'Stride Cambridge – Official Cambridge Walking Tours',
                defaultTourTypeId: 2,
                tourTypeIds: [2],
            };
        case companies.STRIDEPUNTING:
            return {
                name: 'Stride',
                logo: strideLogo,
                logoFooter: strideLogo,
                tel: '01223 953 582',
                findUs: 'https://stridecambridge.com/how-to-find-us/',
                email: 'info@stridecambridge.com',
                termsAndConditions: 'https://stridecambridge.com/terms-and-conditions/',
                address: '30 Milton Road,| Cambridge,| CB4 1JY.',
                completionUrl: 'https://www.stridecambridge.com/thankyou',
                siteTitle: 'Stride Cambridge - Chauffeured Punting Tours',
                defaultTourTypeId: 1,
                tourTypeIds: [1],
            };
        case companies.GLIDE:
            return {
                name: 'Glide',
                logo: glideLogo,
                logoFooter: glideLogoFooter,
                tel: '01223 953 586',
                findUs: '',
                email: 'info@glide-platform.co.uk',
                termsAndConditions: 'https://info.glide-platform.co.uk/terms-conditions/',
                address: '',
                completionUrl: '',
                siteTitle: 'Glide Booking Platform',
                defaultTourTypeId: 1,
                tourTypeIds: [1, 2],
            };
        case companies.HEN:
            return {
                name: 'Cambridge Hen Party',
                logo: henLogo,
                logoFooter: henLogo,
                tel: '01223 778 558',
                findUs: 'https://www.cambridge-hen-party.co.uk',
                email: 'info@cambridge-hen-party.co.uk',
                termsAndConditions: 'https://www.cambridge-hen-party.co.uk/terms-conditions/',
                address: '30 Milton Road,| Cambridge,| CB4 1JY.',
                completionUrl: 'https://www.cambridge-hen-party.co.uk/thank-you',
                siteTitle: 'Cambridge Hen Party - Hen Party Planning Company | Hen Party Activities Cambridge',
                defaultTourTypeId: 1,
                tourTypeIds: [1],
            };
        case companies.TYRRELLS:
            return {
                name: 'Cambridge Punting',
                logo: tyrrellsLogo,
                logoFooter: tyrrellsLogo,
                tel: '+44 1223 782303',
                findUs: 'https://www.cambridgepunting.co/',
                email: 'info@cambridgepunting.co',
                termsAndConditions: 'https://www.cambridgepunting.co/terms-and-conditions/',
                address: 'Landing Stage, Thompsons Lane|Cambridge, CB5 8AG',
                completionUrl: 'https://www.cambridgepunting.co/thank-you',
                siteTitle: 'Cambridge Punting Tours - Tyrrell&#039;s Punting Company',
                defaultTourTypeId: 1,
                tourTypeIds: [1],
            };
        default:
            return undefined;
    }
};
