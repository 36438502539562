import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { apiTicketTypesGet, apiTicketsMaxGet } from '../../api/api-reference-data';
import { companies, companyDetailsGetV3, getAffiliate, getCompanyIdV3, getTourTypeIdV3 } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPage } from '../../helpers/gtm';
import { ActionCard, ErrorDisplay, InputSpinner, Table, TableCell, TableRow } from '../common';

const StepTickets = () => {
    const [data, setData] = useState(undefined);
    const [isValid, setIsValid] = useState(false);
    const [maxTickets, setMaxTickets] = useState(1);
    const [loading, setLoading] = useState(true);
    const { ticketCategory, initialStep, currentStep, back, selectedTickets, selectTickets } = useContext(BookingContext);

    const detailsGet = () => {
        return companyDetailsGetV3();
    };

    useEffect(() => {
        const LoadData = async () => {
            const max = await apiTicketsMaxGet(getTourTypeIdV3(), ticketCategory.id);
            setMaxTickets(max);

            let result = (await apiTicketTypesGet(getTourTypeIdV3(), ticketCategory.id)).filter((x) => !x.isDisabled && (!x.toDate || moment(x.toDate).isSameOrAfter()));

            result.forEach((item) => {
                item.amount = selectedTickets?.find((x) => x.id === item.id)?.amount ?? 0;
                item.ticketTypeId = item.id;
            });

            result = result.filter((x) => !x.isDisabled && x.companyIds.includes(getCompany()));

            const affiliate = getAffiliate();
            if (affiliate) {
                setData({ data: result.filter((x) => x.affiliateId === affiliate.id), errors: '' });
            } else {
                setData({ data: result.filter((x) => !x.affiliateId), errors: '' });
            }

            setLoading(false);
        };

        if (currentStep === 2 && ticketCategory) {
            LoadData();
        }
    }, [ticketCategory, selectedTickets, currentStep]);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/step-tickets');
    }, []);

    const Buttons = () => {
        return (
            <>
                {initialStep < 2 && (
                    <button type="button" onClick={back} className="btn-secondary w-1/3">
                        Back
                    </button>
                )}
                <div className="w-2/3">
                    {isValid && (
                        <button type="submit" className="btn-primary w-full">
                            Next
                        </button>
                    )}
                </div>
            </>
        );
    };

    const calcTotal = (values) => {
        return values.data.reduce((a, b) => a + b.amount, 0);
    };

    const getCompany = () => {
        const company = getCompanyIdV3();
        if (company === companies.STRIDEPUNTING) {
            return companies.STRIDE;
        }

        return company;
    };

    return (
        <ActionCard title="Please Select Your Tickets">
            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validateOnMount
                    validate={(values) => {
                        const errors = {};

                        const total = calcTotal(values);

                        let hasError = false;

                        if (total > maxTickets) {
                            errors.errors = `Max tickets of ${maxTickets} exceeded`;
                            hasError = true;
                        }

                        if (total === 0) {
                            hasError = true;
                        }

                        if (!hasError && [...new Set(values.data.filter((x) => x.amount > 0).map((x) => x.duration))].length > 1) {
                            hasError = true;
                            errors.errors = 'Please only select tickets of the same duration';
                        }

                        setIsValid(!hasError);

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        selectTickets(values.data);
                    }}>
                    {({ values, errors }) => (
                        <Form className="flex flex-col flex-grow">
                            <div className="flex-grow flex flex-col justify-between">
                                <FieldArray
                                    name="data"
                                    render={() => (
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <Table>
                                                <tbody>
                                                    {values.data.map((item, key) => (
                                                        <TableRow key={key} rowNum={key}>
                                                            <TableCell text={item.ticketTypeName} className="whitespace-normal" />
                                                            <TableCell>
                                                                <InputSpinner name={`data.${key}.amount`} max={maxTickets} total={calcTotal(values)} right />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )}
                                />
                                <ErrorDisplay errors={errors} name="errors" className="m-2" align="center" />
                                {ticketCategory.allowSplitAcrossTours && calcTotal(values) > 1 && (
                                    <>
                                        <div className="m-2 text-center highlight-text">
                                            Need more than 2 boats, <br className="sm:hidden" /> call
                                            <a href={`tel:${detailsGet().tel}`} className="underline mx-1">
                                                {detailsGet().tel}
                                            </a>
                                            to book.
                                        </div>
                                    </>
                                )}
                                <div></div>
                            </div>
                            <div className="button-bar">
                                <Buttons />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </ActionCard>
    );
};

export default StepTickets;
