import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { getCompanyIdV3, getGAV3, getGAV4, getTourTypeIdV3 } from '../config';
import { apiGAModeGet } from '../api/api-reference-data';

export const trackInitialise = async () => {
    const mode = await apiGAModeGet(getTourTypeIdV3(), getCompanyIdV3());
    localStorage.setItem('GAMode', mode);

    if (mode === '3' || mode === 'B') {
        ReactGA.initialize(getGAV3());
    }

    if (mode === '4' || mode === 'B') {
        if (getGAV4() !== '!!!') {
            TagManager.initialize({ gtmId: getGAV4() });
        }
    }
};

export const trackPage = (page) => {
    const mode = localStorage.getItem('GAMode');

    if (mode === '3' || mode === 'B') {
        ReactGA.pageview(page);
    }

    if (mode === '4' || mode === 'B') {
        if (getGAV4() !== '!!!') {
            TagManager.dataLayer({ dataLayer: { event: 'pageview', pagePath: page } });
        }
    }
};

export const trackPurchase = (g2BookingId, price, priceBoltOns, ticketCategoryName, selectedTickets, selectedBoltOns) => {
    const mode = localStorage.getItem('GAMode');

    if (mode === '3' || mode === 'B') {
        ReactGA.plugin.require('ecommerce');
        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: g2BookingId,
            revenue: ((price || 0) + (priceBoltOns || 0)).toFixed(2),
        });

        if (selectedTickets) {
            selectedTickets
                .filter((x) => x.amount > 0)
                .forEach((item) => {
                    ReactGA.plugin.execute('ecommerce', 'addItem', {
                        id: g2BookingId, // Transaction ID. Required.
                        sku: item.ticketTypeName, // SKU needed if passing multiple items
                        name: item.ticketTypeName, // Product name. Required.
                        category: ticketCategoryName, // Category or variation.
                        price: item.priceEach.toFixed(2), // Unit price.
                        quantity: item.amount.toString(), // Quantity.
                    });
                });
        }

        if (selectedBoltOns) {
            selectedBoltOns
                .filter((x) => x.amount > 0)
                .forEach((item) => {
                    ReactGA.plugin.execute('ecommerce', 'addItem', {
                        id: g2BookingId, // Transaction ID. Required.
                        sku: item.boltOnName, // SKU needed if passing multiple items
                        name: item.boltOnName, // Product name. Required.
                        category: 'Bolt-On', // Category or variation.
                        price: item.priceEach.toFixed(2), // Unit price.
                        quantity: item.amount.toString(), // Quantity.
                    });
                });
        }
        ReactGA.plugin.execute('ecommerce', 'send');
        ReactGA.plugin.execute('ecommerce', 'clear');
    }

    if (mode === '4' || mode === 'B') {
        if (getGAV4() !== '!!!') {
            const purchase = {
                event: 'purchase',
                ecommerce: {
                    transaction_id: g2BookingId, // Transaction ID- Type: String
                    value: (price || 0) + (priceBoltOns || 0),
                    currency: 'GBP',
                    items: [],
                },
            };
            if (selectedTickets) {
                selectedTickets
                    .filter((x) => x.amount > 0)
                    .forEach((item) => {
                        purchase.ecommerce.items.push({
                            item_name: item.ticketTypeName,
                            item_id: item.ticketTypeName,
                            item_category: ticketCategoryName,
                            price: item.priceEach,
                            quantity: item.amount,
                        });
                    });
            }
            if (selectedBoltOns) {
                selectedBoltOns
                    .filter((x) => x.amount > 0)
                    .forEach((item) => {
                        purchase.ecommerce.items.push({
                            item_name: item.boltOnName,
                            item_id: item.boltOnName,
                            item_category: 'Bolt-On',
                            price: item.priceEach,
                            quantity: item.amount,
                        });
                    });
            }
            TagManager.dataLayer({ dataLayer: purchase });
        }
    }
};
