import axios from 'axios';
import { companies } from '../config';

export const apiTicketCategoriesGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/TicketCategories',
        params: { tourTypeId },
    });
    return result.data;
};

export const apiTicketCategoryGet = async (tourTypeId, id) => {
    const category = (await apiTicketCategoriesGet(tourTypeId)).find((x) => x.id === parseInt(id));

    return category;
};

export const apiTicketsMaxGet = async (tourTypeId, ticketCategoryId) => {
    const category = (await apiTicketCategoriesGet(tourTypeId)).find((x) => x.id === ticketCategoryId);

    return category?.maxTicketsPerBooking ?? 1;
};

export const apiTicketTypesGet = async (tourTypeId, ticketCategoryId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/TicketTypes',
        params: { tourTypeId, ticketCategoryId },
    });
    return result.data;
};

export const apiGAModeGet = async (tourTypeId, companyId) => {
    if (companyId === companies.STRIDEPUNTING) {
        companyId = companies.STRIDE;
    }

    const result = await axios({
        method: 'get',
        url: '/api/v1/GAMode',
        params: { tourTypeId, companyId },
    });
    return `${result.data}`;
};
