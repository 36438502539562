import React from 'react';

export default function Error() {
    return (
        <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="card p-2 bg-gray-100 text-center">
                    <div className="text-lg font-semibold border-b-2 border-blue-700">Error</div>
                    <p className="my-4">Something unexpected has occured.</p>
                    <p className="">
                        <a href="/">
                            <i className="fad fa-undo" /> Reload <i className="fad fa-undo" />
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
