import React, { useContext, useEffect, useState } from 'react';
import { apiAffiliateLogout } from './api/api-affiliate';
import { configureAxios } from './api/axios-helper';
import Login from './components/affiliates/login';
import Error from './components/errors/error';
import Footer from './components/layout/footer';
import Header from './components/layout/header';
import { StepComplete, StepContactDetails, StepCOVID, StepDate, StepPayment, StepTickets, StepTicketType, StepTime } from './components/steps';
import StepBoltOns from './components/steps/step-bolt-ons';
import { companies, companyDetailsGetV3, getAffiliate, getCompanyIdV3, getTourTypeIdV3, isAffiliate, setTourTypeIdV3, tourTypes } from './config';
import { BookingContext } from './contexts/booking-context';
import { trackInitialise } from './helpers/gtm';

configureAxios();

function App() {
    const { currentStep, inError } = useContext(BookingContext);
    const [gaModeChecked, setGAModeChecked] = useState(false);

    const getTheme = () => {
        const companyId = getCompanyIdV3();

        switch (companyId) {
            case companies.STRIDEPUNTING:
                return companies.STRIDE.toLowerCase();
            default:
                return companyId.toLowerCase();
        }
    };

    useEffect(() => {
        const initGA = async () => {
            await trackInitialise();
            setGAModeChecked(true);
        };
        initGA();
    }, []);

    if (isAffiliate() && !getAffiliate()) {
        return <Login />;
    }

    if (inError) {
        return (
            <div className={`${getTheme()} px-4`}>
                <Header />
                <Error />
            </div>
        );
    }

    // trackInitialise();
    // ReactGA.initialize(getGAV3());
    // if (getGAV4() !== '!!!') {
    //     TagManager.initialize({ gtmId: getGAV4() });
    // }

    const SwitchTourType = () => {
        const tourTypeIds = companyDetailsGetV3().tourTypeIds;
        if (tourTypeIds.length <= 1) {
            return null;
        }

        return tourTypeIds
            .filter((x) => x !== getTourTypeIdV3())
            .map((tt) => (
                <div onClick={() => setTourTypeIdV3(tt)} key={tt} className="cursor-pointer">
                    Switch to {tourTypes.find((x) => x.id === tt).name}
                </div>
            ));
    };

    return (
        <div className={`${getTheme()} min-h-screen flex flex-col`}>
            <div>
                <Header />
            </div>
            <div className="flex-grow flex px-4">
                {gaModeChecked && (
                    <>
                        {currentStep === 1 && <StepTicketType />}
                        {currentStep === 2 && <StepTickets />}
                        {currentStep === 19 && <StepCOVID />}
                        {currentStep === 3 && <StepDate />}
                        {currentStep === 4 && <StepTime />}
                        {currentStep === 5 && <StepBoltOns />}
                        {currentStep === 6 && <StepContactDetails />}
                        {currentStep === 7 && <StepPayment />}
                        {currentStep === 20 && <StepComplete />}
                    </>
                )}
            </div>
            {getAffiliate() && (
                <div className="border-t border-b border-gray-600 text-center p-2 flex justify-around">
                    <SwitchTourType />
                    <button onClick={apiAffiliateLogout}>Logout</button>
                </div>
            )}
            <div>
                <Footer />
            </div>
        </div>
    );
}

export default App;
