import React from 'react';

const Table = ({ children, fullWidth = true, auto = false }) => {
    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className={`${fullWidth ? 'min-w-full max-w-full' : ''} table-wrapper-outer`}>
                    <div className="table-wrapper-inner">
                        <table className={`${fullWidth ? 'min-w-full max-w-full' : ''} ${auto ? 'table-auto' : ''} divide-y divide-gray-200`}>{children}</table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
