import { useField } from 'formik';
import React from 'react';

const InputText = ({ label, type = 'text', required, maxLength, onChange, wrapperClassName, inputClassName, ...props }) => {
    const [field, meta] = useField(props);

    const handleChange = (e) => {
        field.onChange(e);

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className={wrapperClassName}>
            {label && <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">{label}</div>}
            <div className={`rounded-md shadow-sm  ${inputClassName}`}>
                <input
                    name={field.name}
                    id={field.name}
                    onBlur={field.onBlur}
                    onChange={handleChange}
                    value={field.value}
                    placeholder={label}
                    type={type}
                    maxLength={maxLength}
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${inputClassName}`}
                />
            </div>
            {meta.error && <div className="validation-error text-left">{meta.error}</div>}
        </div>
    );
};

export default InputText;
