import React, { useContext, useEffect } from 'react';
import { companyDetailsGetV3, getAffiliate } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPage } from '../../helpers/gtm';
import { ActionCard } from '../common';

const StepComplete = () => {
    const { price, priceBoltOns } = useContext(BookingContext);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/complete');

        if (!getAffiliate()) {
            if (companyDetailsGetV3().completionSendAmount) {
                const totalPrice = (price + priceBoltOns).toString();
                window.location = `${companyDetailsGetV3().completionUrl}?amount=${totalPrice}`;
            } else {
                window.location = companyDetailsGetV3().completionUrl;
            }
        }
    }, [price, priceBoltOns]);

    const newBooking = () => {
        window.location = '/';
    };

    return (
        <>
            {getAffiliate() ? (
                <ActionCard title="Booking Summary">
                    <div className="flex flex-grow flex-col"></div>
                    <div className="button-bar">
                        <button type="button" onClick={newBooking} className="btn-primary w-full">
                            New Booking
                        </button>
                    </div>
                </ActionCard>
            ) : (
                <div className="flex flex-col flex-grow justify-center">
                    <span className="text-center">
                        <span className="fas fa-spinner fa-spin fa-2x" />
                    </span>
                </div>
            )}
        </>
    );
};

export default StepComplete;
