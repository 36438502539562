import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import pinTpcPng from '../../assets/icons/pin-tpc.png';
import telTpcPng from '../../assets/icons/tel-tpc.png';
import { companies, companyDetailsGetV3, getAffiliate, getCompanyIdV3, logoUrl } from '../../config';
import strideFav32 from '../../assets/favicons/Stride-32x32.png';
import strideFav180 from '../../assets/favicons/Stride-180x180.png';
import strideFav192 from '../../assets/favicons/Stride-192x192.png';
import strideFav270 from '../../assets/favicons/Stride-270x270.png';
import glideFav32 from '../../assets/favicons/Glide-32x32.png';
import glideFav180 from '../../assets/favicons/Glide-180x180.png';
import glideFav192 from '../../assets/favicons/Glide-192x192.png';
import glideFav270 from '../../assets/favicons/Glide-270x270.png';
import lgpFav32 from '../../assets/favicons/LGP-32x32.png';
import lgpFav180 from '../../assets/favicons/LGP-180x180.png';
import lgpFav192 from '../../assets/favicons/LGP-192x192.png';
import lgpFav270 from '../../assets/favicons/LGP-270x270.png';
import tpcFav32 from '../../assets/favicons/TPC-32x32.jpg';
import tpcFav180 from '../../assets/favicons/TPC-180x180.jpg';
import tpcFav192 from '../../assets/favicons/TPC-192x192.jpg';
import tpcFav270 from '../../assets/favicons/TPC-270x270.jpg';
import henFav from '../../assets/favicons/Hen.ico';
import henFav16 from '../../assets/favicons/Hen-16x16.png';
import henFav32 from '../../assets/favicons/Hen-32x32.png';
import henFav180 from '../../assets/favicons/Hen-180x180.png';
import tyrrellsFav32 from '../../assets/favicons/Tyrrells-32x32.png';
import tyrrellsFav180 from '../../assets/favicons/Tyrrells-180x180.png';
import tyrrellsFav192 from '../../assets/favicons/Tyrrells-192x192.png';
import tyrrellsFav270 from '../../assets/favicons/Tyrrells-270x270.png';

const Header = () => {
    const [hideAffilateLogo, setHideAffiliateLogo] = useState(false);

    const detailsGet = () => {
        return companyDetailsGetV3();
    };

    if (!detailsGet()) {
        return null;
    }

    //LGP
    if (getCompanyIdV3().toUpperCase() === companies.LGP) {
        return (
            <>
                <Helmet>
                    <title>{detailsGet().siteTitle}</title>
                    <link rel="icon" href={lgpFav32} sizes="32x32" />
                    <link rel="icon" href={lgpFav192} sizes="192x192" />
                    <link rel="apple-touch-icon-precomposed" href={lgpFav180} />
                    <meta name="msapplication-TileImage" content={lgpFav270} />
                </Helmet>
                <div className="bg-gray-100">
                    <div className="flex items-center justify-between max-w-screen-md mx-auto px-4 py-2">
                        <div className="flex-1 min-w-0">
                            <a href="https://www.letsgopunting.co.uk/">
                                <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="h-20 object-contain pr-4" />
                            </a>
                        </div>
                        <div className="sm:flex items-center sm:space-x-6">
                            <a href={`tel:${detailsGet().tel}`} className="flex items-center">
                                <i className="fas fa-phone-alt text-orange-500 mt-1"></i>
                                <span className="font-semibold ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal">{detailsGet().tel}</span>
                            </a>
                            <a href={`mailto:${detailsGet().email}`} target="_blank" rel="noopener noreferrer" className="flex items-center ml-auto">
                                <i className="fas fa-envelope text-orange-500 mt-1"></i>
                                <span className="font-semibold ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal">{detailsGet().email}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    //Stride
    if (getCompanyIdV3().toUpperCase() === companies.STRIDE || getCompanyIdV3().toUpperCase() === companies.STRIDEPUNTING) {
        return (
            <>
                <Helmet>
                    <title>{detailsGet().siteTitle}</title>
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap" rel="stylesheet" />
                    <link rel="icon" href={strideFav32} sizes="32x32" />
                    <link rel="icon" href={strideFav192} sizes="192x192" />
                    <link rel="apple-touch-icon-precomposed" href={strideFav180} />
                    <meta name="msapplication-TileImage" content={strideFav270} />
                </Helmet>
                <div className="flex items-center justify-between max-w-screen-md mx-auto px-4 py-2">
                    <div className="flex-1 min-w-0">
                        <a href="https://stridecambridge.com/">
                            <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="h-20 object-contain pr-4" />
                        </a>
                    </div>
                    <div className="sm:flex items-center sm:space-x-6">
                        <a href={`tel:${detailsGet().tel}`} className="flex items-center">
                            <i className="fas fa-phone-alt text-blue-900 mt-1"></i>
                            <span className="font-semibold ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal">{detailsGet().tel}</span>
                        </a>
                        <a href={`mailto:${detailsGet().email}`} target="_blank" rel="noopener noreferrer" className="flex items-center ml-auto">
                            <i className="fas fa-envelope text-blue-900 mt-1"></i>
                            <span className="font-semibold ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal">{detailsGet().email}</span>
                        </a>
                    </div>
                </div>
            </>
        );
    }

    //TPC
    if (getCompanyIdV3().toUpperCase() === companies.TPC) {
        return (
            <>
                <Helmet>
                    <title>{detailsGet().siteTitle}</title>
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link rel="preload" as="font" href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap" />
                    <link rel="icon" href={tpcFav32} sizes="32x32" />
                    <link rel="icon" href={tpcFav192} sizes="192x192" />
                    <link rel="apple-touch-icon-precomposed" href={tpcFav180} />
                    <meta name="msapplication-TileImage" content={tpcFav270} />
                </Helmet>
                <div className="tpc-top-border"></div>
                <div className="flex items-center justify-between max-w-4xl md:max-w-6xl xl:max-w-7xl mx-auto px-4 pt-4">
                    <div className="flex-1 min-w-0">
                        <a href="https://www.traditionalpuntingcompany.com/">
                            <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="pr-4 tpc-logo" />
                        </a>
                    </div>
                    <div className="sm:flex items-center sm:space-x-6">
                        <a href={detailsGet().findUs} target="_blank" rel="noopener noreferrer" className="flex items-center ml-auto">
                            <img src={pinTpcPng} alt="Find Us" className="h-4 sm:h-6" />
                            <span className="font-medium ml-2 text-base tracking-tight sm:tracking-normal">Find Us</span>
                        </a>
                        <a href={`tel:${detailsGet().tel}`} className="flex items-center">
                            <img src={telTpcPng} alt="Call Us" className="h-4 sm:h-6" />
                            <span className="font-medium ml-2 text-base tracking-tight sm:tracking-normal">{detailsGet().tel}</span>
                        </a>
                    </div>
                </div>
            </>
        );
    }

    //Glide
    if (getCompanyIdV3().toUpperCase() === companies.GLIDE) {
        return (
            <>
                <Helmet>
                    <title>{detailsGet().siteTitle}</title>
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap" rel="stylesheet" />
                    <link rel="icon" href={glideFav32} sizes="32x32" />
                    <link rel="icon" href={glideFav192} sizes="192x192" />
                    <link rel="apple-touch-icon-precomposed" href={glideFav180} />
                    <meta name="msapplication-TileImage" content={glideFav270} />
                </Helmet>
                <div className="bg-glide-700">
                    <div className="flex items-center justify-between max-w-screen-sm mx-auto px-4 py-2">
                        <div className="min-w-0">
                            <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="object-contain h-32" />
                        </div>
                        {logoUrl() && !hideAffilateLogo && (
                            <div className="min-w-0">
                                <img src={logoUrl()} title={getAffiliate().affiliateName} alt={getAffiliate().affiliateName} className="object-contain h-28" onError={() => setHideAffiliateLogo(true)} />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }

    //Hen
    if (getCompanyIdV3().toUpperCase() === companies.HEN) {
        return (
            <>
                <Helmet>
                    <title>{detailsGet().siteTitle}</title>
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;800&display=swap" rel="stylesheet" />
                    <link href="https://fonts.googleapis.com/css2?family=Lobster&display=swap" rel="stylesheet"></link>
                    <link rel="icon" href={henFav} />
                    <link rel="icon" type="image/png" sizes="16x16" href={henFav16} />
                    <link rel="icon" type="image/png" sizes="32x32" href={henFav32} />
                    <link rel="apple-touch-icon" sizes="180x180" href={henFav180} />
                </Helmet>
                <div className="bg-white border-b border-gray-200 hen-title-font text-hen-900">
                    <div className="flex items-center justify-between max-w-screen-md mx-auto px-4">
                        <div className="flex-1 min-w-0">
                            <a href="https://www.cambridge-hen-party.co.uk/">
                                <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="h-20 object-contain pr-4" />
                            </a>
                        </div>
                        <div className="sm:flex items-center sm:space-x-6">
                            <a href={`tel:${detailsGet().tel}`} className="flex items-center">
                                <i className="fas fa-phone text-hen-500 mt-1"></i>
                                <span className="ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal">{detailsGet().tel}</span>
                            </a>
                            <a href={`mailto:${detailsGet().email}`} target="_blank" rel="noopener noreferrer" className="flex items-center ml-auto">
                                <i className="fas fa-envelope text-hen-500 mt-1"></i>
                                <span className="ml-2 text-base sm:text-xl tracking-tight sm:tracking-normal">{detailsGet().email}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    //Tyrrells
    if (getCompanyIdV3().toUpperCase() === companies.TYRRELLS) {
        return (
            <>
                <Helmet>
                    <title>{detailsGet().siteTitle}</title>
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link
                        rel="stylesheet"
                        id="et-builder-googlefonts-cached-css"
                        href="https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic|Heebo:100,200,300,regular,500,600,700,800,900|Cormorant:300,regular,500,600,700,300italic,italic,500italic,600italic,700italic|Cormorant+Infant:300,300italic,regular,italic,500,500italic,600,600italic,700,700italic&amp;subset=latin,latin-ext&amp;display=swap"
                        type="text/css"
                        media="all"
                    />
                    <link rel="icon" href={tyrrellsFav32} sizes="32x32" />
                    <link rel="icon" href={tyrrellsFav192} sizes="192x192" />
                    <link rel="apple-touch-icon-precomposed" href={tyrrellsFav180} />
                    <meta name="msapplication-TileImage" content={tyrrellsFav270} />
                </Helmet>
                <div className="bg-tyrrells-500 text-white">
                    <div className="flex items-center justify-between max-w-screen-md mx-auto px-4">
                        <div className="flex-1 min-w-0">
                            <a href="https://www.cambridgepunting.co/" className="p-1">
                                <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="h-24 object-contain" />
                            </a>
                        </div>
                        <div className="sm:flex items-center sm:space-x-6">
                            <a href={`tel:${detailsGet().tel}`} className="flex items-center">
                                <span className="ml-2 text-base sm:text-lg font-bold">{detailsGet().tel}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    //Fallback
    return (
        <>
            <Helmet>
                <title>{detailsGet().siteTitle}</title>
            </Helmet>
            <div className="flex items-center justify-center max-w-screen-md mx-auto px-4 pt-4">
                <div className="min-w-0">
                    <img src={detailsGet().logo} title={detailsGet().name} alt={detailsGet().name} className="h-16 object-contain pr-4" />
                </div>
                <div className="font-extrabold text-2xl text-gray-600">{detailsGet().siteTitle}</div>
            </div>
        </>
    );
};

export default Header;
