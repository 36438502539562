import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { apiAvailability, apiAvailableTimes, apiCreateInProgressBooking } from '../../api/api-booking';
import { apiCalculatePrice } from '../../api/api-calculate-price';
import { StatusCodeOK } from '../../api/axios-helper';
import { getCompanyIdV3, getTourTypeIdV3 } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { addDays } from '../../helpers/date-helpers';
import { trackPage } from '../../helpers/gtm';
import { ErrorDisplay, FontAwesomeIcon, InputText } from '../common';
import ActionCard from '../common/action-card';

const StepTime = () => {
    const { date, before, after, back, next, ticketCategoryId, selectedTickets, time, selectTime, discountCode, setDiscountCode, setSingleUseDiscountAmount, happyToSitOpposite, setG2BookingId, currentDate, setCurrentDate, selectPrice, startTimer } =
        useContext(BookingContext);
    const [loading, setLoading] = useState(true);
    const [working, setWorking] = useState(false);
    const [availableDates, setAvailableDates] = useState(undefined);
    const [prices, setPrices] = useState(undefined);
    const [timeAvailability, setTimeAvailability] = useState([]);
    const [lowestPrice, setLowestPrice] = useState(undefined);
    const [days, setDays] = useState([]);
    const [errors, setErrors] = useState(undefined);
    const buttonRef = useRef(null);

    const LoadTimes = useCallback(
        async (currentDate) => {
            if (currentDate && selectedTickets) {
                setLoading(true);

                const timesResult = await apiAvailableTimes(getTourTypeIdV3(), ticketCategoryId, selectedTickets, currentDate, happyToSitOpposite, before, after);
                setTimeAvailability(timesResult);

                const priceResult = await apiCalculatePrice(getTourTypeIdV3(), currentDate, ticketCategoryId, selectedTickets, discountCode);

                setSingleUseDiscountAmount(undefined);
                setErrors(undefined);
                if (discountCode) {
                    let discountFound = false;
                    let singleUseFound = false;
                    priceResult.forEach((pr) => {
                        if (pr.singleUseDiscountCode === discountCode) {
                            discountFound = true;
                            singleUseFound = true;
                            setSingleUseDiscountAmount(pr.singleUseDiscountAmount);
                        }
                        pr.allTicketPrices.forEach((ap) => {
                            if (ap.discountCode === discountCode) {
                                discountFound = true;
                            }
                        });
                    });

                    if (!discountFound) {
                        setErrors('Invalid Promo Code');
                        setDiscountCode(undefined);
                    } else {
                        if (singleUseFound) {
                            setErrors('Credit applied successfully and will be removed from payment total at checkout.');
                        } else {
                            setErrors(undefined);
                        }
                    }
                }

                setPrices(priceResult);

                setLoading(false);
            }
        },
        [ticketCategoryId, selectedTickets, discountCode, happyToSitOpposite, before, after, setSingleUseDiscountAmount, setDiscountCode]
    );

    useEffect(() => {
        let working = 99999;
        if (prices && prices.length > 0) {
            prices.forEach((price) => {
                if (price.total < working && timeAvailability.find((x) => `${x.time}:00` === price.timeSlot && x.available)) {
                    working = price.total;
                }
            });
        }
        setLowestPrice(working);
    }, [prices, timeAvailability]);

    const LoadData = useCallback(async () => {
        if (selectedTickets) {
            const datesResult = await apiAvailability(getTourTypeIdV3(), ticketCategoryId, selectedTickets, null, date, null, happyToSitOpposite, true, before, after);
            setAvailableDates(datesResult);

            const result = [date];
            for (let i = 1; i <= 6; ++i) {
                result.push(addDays(date, i));
            }

            setDays(result);
            let dateToUse = date;

            if (!datesResult.some((x) => x.getFullYear() === date.getFullYear() && x.getMonth() === date.getMonth() && x.getDate() === date.getDate())) {
                dateToUse = addDays(date, 1);
            }
            setCurrentDate(dateToUse);
            LoadTimes(dateToUse);
        }
    }, [date, ticketCategoryId, selectedTickets, happyToSitOpposite, setCurrentDate, LoadTimes, before, after]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/step-time');
    }, []);

    const createInProgressBooking = async () => {
        setWorking(true);
        setErrors(undefined);
        const foundPrice = prices.find((x) => x.timeSlot === `${time}:00`);

        if (foundPrice) {
            selectedTickets.forEach((ticket) => {
                const foundPriceTicket = foundPrice.ticketPrices.find((x) => x.ticketTypeId === ticket.ticketTypeId);

                ticket.priceDescription = foundPriceTicket?.description || 'Unknown';
                ticket.priceEach = foundPriceTicket?.price;
            });

            const response = await apiCreateInProgressBooking(getTourTypeIdV3(), getCompanyIdV3(), ticketCategoryId, selectedTickets, currentDate, time, happyToSitOpposite, discountCode, foundPrice.total);

            if (response.status !== StatusCodeOK) {
                setErrors(response.error || 'An Error Occurred');
                selectTime(undefined);
                LoadTimes(currentDate);
                setWorking(false);
                return;
            }

            selectPrice(foundPrice.total);

            setG2BookingId(response.id);
            startTimer();
            next();
        }
        setWorking(false);
    };

    const Buttons = () => {
        return (
            <>
                <button type="button" onClick={back} className="btn-secondary w-1/3" disabled={working}>
                    Back
                </button>
                <div className="w-2/3">
                    {time && (
                        <button type="button" onClick={createInProgressBooking} className="btn-primary w-full" disabled={working}>
                            Next
                        </button>
                    )}
                </div>
            </>
        );
    };

    const isBadDay = (day) => {
        return !availableDates.some((x) => x.getFullYear() === day.getFullYear() && x.getMonth() === day.getMonth() && x.getDate() === day.getDate());
    };

    const selectDay = (day) => {
        if (!isBadDay(day)) {
            setCurrentDate(day);
            LoadTimes(day);
        }
    };

    const DateButton = ({ day }) => {
        const m = moment(day);

        let statusColour = 'good-day-status';
        let selectedColour = 'good-day-selected';

        if (isBadDay(day)) {
            statusColour = 'bad-day-status';
            selectedColour = 'bad-day-selected';
        }

        let selected = m.isSame(currentDate) ? selectedColour : statusColour;

        return (
            <div className={`flex flex-shrink-0 flex-col items-center justify-center shadow-md h-12 w-12 sm:h-16 sm:w-16 leading-3 sm:leading-5 font-medium rounded-full relative ${selected}`} onClick={() => selectDay(day)}>
                <div className="text-xs uppercase z-50">{m.format('ddd')}</div>
                <div className="text-sm">{m.format('Do')}</div>
                {isBadDay(day) && (
                    <div className="absolute">
                        <FontAwesomeIcon className="fa-3x opacity-50" icon="times" />
                    </div>
                )}
            </div>
        );
    };

    const selectedTimeClass = (optionTime) => {
        if (optionTime === time) {
            return 'selected-time';
        }
        return 'unselected-time';
    };

    const trySelectTime = (timeOption) => {
        if (timeOption.available) {
            selectTime(timeOption.time);
            buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const PriceBlock = ({ timeOption }) => {
        if (!prices) {
            return null;
        }

        const timeSlot = `${timeOption.time}:00`;
        const found = prices.find((x) => x.timeSlot === timeSlot);

        if (!found) {
            return null;
        }

        const isLowest = found.total === lowestPrice;
        const priceClass = isLowest ? 'font-semibold' : '';

        return (
            <div className="flex shadow-sm rounded-md mx-auto cursor-pointer bg-white hover:bg-gray-200" onClick={() => trySelectTime(timeOption)}>
                <div className="w-24 flex items-center justify-center time-option text-sm leading-5 font-medium rounded-l-md border-r-2 border-white">
                    {isLowest && timeOption.available && <FontAwesomeIcon icon="star" className="mr-2 fa-swap-opacity" colour="white" hoverColour="white" />}
                    {timeOption.time}
                </div>
                <div className={`w-48 flex items-center justify-center border-t border-r border-b ${selectedTimeClass(timeOption.time)} rounded-r-md truncate`}>
                    <div className="px-4 py-2 text-sm leading-5 truncate text-center">{!timeOption.available ? <span className="text-gray-300 font-semibold">SOLD OUT</span> : <span className={priceClass}>£ {found.total.toFixed(2)}</span>}</div>
                </div>
            </div>
        );
    };

    return (
        <ActionCard title="Please Select Your Time">
            <div className="flex flex-col flex-grow">
                <div className="flex-grow flex flex-col">
                    <div className="overflow-x-auto w-screen sm:w-full bg-gray-300 mb-2 h-18 sm:h-20">
                        <div className="flex flex-row p-1 sm:p-2 space-x-1 h-16 sm:h-20 sm:justify-between">
                            {days.map((day, key) => (
                                <DateButton key={key} day={day} />
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-grow flex-col justify-between">
                        {loading ? (
                            <div className="text-center">
                                <i className="fad fa-spinner fa-pulse fa-lg"></i>
                            </div>
                        ) : (
                            <>
                                {timeAvailability && timeAvailability.length > 0 ? (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">{prices && timeAvailability.map((timeOption, key) => <PriceBlock key={key} timeOption={timeOption} />)}</div>
                                ) : (
                                    <div className="grid grid-cols-1 justify-center">
                                        <div className="flex shadow-sm rounded-md mx-auto cursor-pointer bg-white hover:bg-gray-200">
                                            <div className="w-24 flex items-center justify-center time-option text-sm leading-5 font-medium rounded-l-md border-r-2 border-white">ALL</div>
                                            <div className="w-48 flex items-center justify-center border-t border-r border-b border-gray-200 rounded-r-md truncate">
                                                <div className="px-4 py-2 text-sm leading-5 truncate text-center">
                                                    <span className="text-gray-300 font-semibold">SOLD OUT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        <ErrorDisplay errors={errors} name="errors" className="m-2" align="center" />
                    </div>
                </div>
                <Formik
                    initialValues={{ discountCode: discountCode || '' }}
                    enableReinitialize
                    onSubmit={async (values) => {
                        setDiscountCode(values.discountCode);
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 flex gap-x-2 items-center pt-2">
                            <div className="leading-3 text-sm font-semibold">
                                Promo <span className="hidden sm:inline">Code</span>
                            </div>
                            <InputText name="discountCode" wrapperClassName="flex-grow" />
                            <button type="submit" className="btn-secondary" disabled={isSubmitting}>
                                Apply
                            </button>
                        </Form>
                    )}
                </Formik>

                <div className="button-bar" ref={buttonRef}>
                    <Buttons />
                </div>
            </div>
        </ActionCard>
    );
};

export default StepTime;
