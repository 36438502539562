import { FieldArray, Form, Formik } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { apiBoltOnsGetAvailabile } from '../../api/api-bolt-ons';
import { apiAddBoltOnsToInProgressBooking } from '../../api/api-booking';
import { StatusCodeOK } from '../../api/axios-helper';
import { getTourTypeIdV3 } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPage } from '../../helpers/gtm';
import { ActionCard, Help, InputSpinner, InputText, Table, TableCell, TableRowManual } from '../common';
import Modal from '../common/modal';

const StepBoltOns = () => {
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [verify, setVerify] = useState(false);
    const { g2BookingId, next, setSelectedBoltOns, selectPriceBoltOns, restart, selectedTickets, allowedBoltOns } = useContext(BookingContext);

    useEffect(() => {
        const loadData = async () => {
            let result = await apiBoltOnsGetAvailabile(getTourTypeIdV3(), g2BookingId);

            if (!result) {
                next();
                return;
            }

            if (allowedBoltOns) {
                result = result.filter((x) => allowedBoltOns.includes(x.id));
            }

            if (!result || result.length === 0) {
                next();
                return;
            }

            const workingCategories = [];
            result.forEach((item) => {
                item.boltOnId = item.id;
                item.amount = 0;
                item.priceEach = item.price;
                item.answer = '';

                if (!workingCategories.some((x) => x.boltOnCategoryId === item.boltOnCategoryId)) {
                    workingCategories.push({ boltOnCategoryId: item.boltOnCategoryId, boltOnCategoryName: item.boltOnCategoryName, boltOnCategoryOrderNum: item.boltOnCategoryOrderNum });
                }
            });

            workingCategories.sort((a, b) => a.boltOnCategoryOrderNum - b.boltOnCategoryOrderNum);
            setCategories(workingCategories);

            result = result.filter((x) => !x.isDisabled);

            setData(result);
            setLoading(false);
        };

        loadData();
    }, [g2BookingId, next, allowedBoltOns]);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/step-bolt-ons');
    }, []);

    const Buttons = () => {
        return (
            <>
                <button type="button" onClick={restart} className="btn-secondary w-1/3">
                    Restart
                </button>
                <button type="submit" className="btn-primary w-2/3">
                    Next
                </button>
            </>
        );
    };

    const calcTotalPrice = (values) => {
        return values.data.reduce((a, b) => a + b.amount * b.price, 0);
    };

    const calcMax = (boltOn) => {
        let max = 99;

        if (boltOn.maxAllowed && boltOn.maxAllowed < max) {
            max = boltOn.maxAllowed;
        }

        if (boltOn.limitToGroupSize) {
            const groupSize = selectedTickets.filter((x) => x.amount > 0).reduce((a, b) => a + b.amount * b.groupSize, 0);

            if (groupSize < max) {
                max = groupSize;
            }
        }

        return max;
    };

    const validationSchema = Yup.object().shape({
        data: Yup.array().of(
            Yup.object().shape({
                answer: Yup.string().when(['amount', 'questionMandatory'], (amount, questionMandatory) => {
                    return amount > 0 && questionMandatory ? Yup.string().required('Required') : Yup.string();
                }),
            })
        ),
    });

    const checkNoExtras = (data) => {
        const count = data.reduce((acc, cur) => acc + cur.amount, 0);

        if (count > 0) {
            setVerify(true);
        } else {
            next();
        }
    };

    return (
        <>
            <Modal show={verify} title="Are You Sure?">
                <div className="py-2 text-center w-full">This will ignore the extras you have picked.</div>
                <div className="button-bar">
                    <button type="button" onClick={() => setVerify(false)} className="btn-secondary w-1/3">
                        No
                    </button>
                    <button type="button" onClick={() => next()} className="btn-primary w-2/3">
                        Yes
                    </button>
                </div>
            </Modal>
            {data && (
                <ActionCard title="Please Select Your Optional Extras">
                    {!loading && (
                        <Formik
                            initialValues={{ data }}
                            enableReinitialize
                            validateOnMount
                            validationSchema={validationSchema}
                            onSubmit={async (values) => {
                                setSelectedBoltOns(values.data);

                                const totalBoltOnPrice = calcTotalPrice(values);
                                const result = await apiAddBoltOnsToInProgressBooking(g2BookingId, getTourTypeIdV3(), totalBoltOnPrice, values.data);

                                if (result.status === StatusCodeOK) {
                                    selectPriceBoltOns(totalBoltOnPrice);
                                    next();
                                }
                            }}>
                            {({ values }) => (
                                <Form className="flex flex-col flex-grow">
                                    <div className="button-bar">
                                        <button type="button" className="btn-primary w-full" onClick={() => checkNoExtras(values.data)}>
                                            Continue Without Extras
                                        </button>
                                    </div>
                                    <div className="flex-grow flex flex-col justify-between">
                                        <FieldArray
                                            name="data"
                                            render={() => (
                                                <Table>
                                                    <tbody>
                                                        {categories.map((category) => (
                                                            <Fragment key={category.boltOnCategoryId}>
                                                                <TableRowManual key={`Category_${category.boltOnCategoryId}`} className="odd-stripe">
                                                                    <TableCell colSpan={3} className="font-extrabold">
                                                                        {category.boltOnCategoryName}
                                                                    </TableCell>
                                                                </TableRowManual>
                                                                {values.data.map((item, key) => (
                                                                    <Fragment key={key}>
                                                                        {item.boltOnCategoryId === category.boltOnCategoryId && (
                                                                            <TableRowManual key={key} className="odd-stripe">
                                                                                <TableCell>
                                                                                    <div>
                                                                                        {item.boltOnName}
                                                                                        <Help help={item.description} />
                                                                                    </div>
                                                                                    {item.amount > 0 && item.question && (
                                                                                        <>
                                                                                            <div>{item.question}</div>
                                                                                            <div>
                                                                                                <InputText name={`data.${key}.answer`} />
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell right text={`£${item.priceEach.toFixed(2)}`} />
                                                                                <TableCell>
                                                                                    <InputSpinner name={`data.${key}.amount`} min={item.minAllowed || 1} max={calcMax(item)} total={0} right />
                                                                                </TableCell>
                                                                            </TableRowManual>
                                                                        )}
                                                                    </Fragment>
                                                                ))}
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            )}
                                        />
                                    </div>
                                    <div className="button-bar">
                                        <Buttons />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </ActionCard>
            )}
        </>
    );
};

export default StepBoltOns;
