import axios from 'axios';
import { fixDate } from '../helpers/date-helpers';

export const apiCalculatePrice = async (tourTypeId, tourDate, ticketCategoryId, tickets, discountCode) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/CalculatePrice',
        data: { tourTypeId, boughtDate: fixDate(new Date()), tourDate: fixDate(tourDate), ticketCategoryId, tickets, discountCode },
    });
    return result.data;
};
