import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { apiBookingSummaryContactNumberValidator, apiBookingSummarySave } from '../../api/api-booking';
import { StatusCodeOK } from '../../api/axios-helper';
import { companyDetailsGetV3, getAffiliate, getTourTypeIdV3 } from '../../config';
import { BookingContext } from '../../contexts/booking-context';
import { trackPage } from '../../helpers/gtm';
import { InputCheckbox, InputText } from '../common';
import ActionCard from '../common/action-card';

const StepContactDetails = () => {
    const defaultValues = {
        name: '',
        email: '',
        mobile: '',
        notes: '',
        accept: false,
    };
    const [working, setWorking] = useState(false);
    const [formattedContactNumber, setFormattedContactNumber] = useState(undefined);
    const { g2BookingId, next, complete, restart, setContactDetails } = useContext(BookingContext);

    useEffect(() => {
        window.scrollTo(0, 0);

        trackPage('/step-contact-details');
    }, []);

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().required('Required').email('Invalid email address'),
        mobile: Yup.string()
            .required('Required')
            .test('contactNumberIsValid', 'Invalid mobile number', async (value) => {
                var result = await apiBookingSummaryContactNumberValidator(value);
                setFormattedContactNumber(result.formattedContactNumber);
                return result.isValid;
            }),
        accept: Yup.boolean().oneOf([true], 'Terms and Conditions Must Be Agreed To'),
    });

    const detailsGet = () => {
        return companyDetailsGetV3();
    };

    const Buttons = () => {
        return (
            <>
                <button type="button" onClick={restart} className="btn-secondary w-1/3">
                    Restart
                </button>

                <button type="submit" className="btn-primary w-2/3" disabled={working}>
                    {getAffiliate() ? 'Complete Booking' : 'Next'}
                </button>
            </>
        );
    };

    return (
        <ActionCard title="Please Enter Your Contact Details">
            <div className="flex flex-col flex-grow">
                <Formik
                    initialValues={defaultValues}
                    enableReinitialize
                    validationSchema={formSchema}
                    validateOnBlur
                    validateOnChange={false}
                    onSubmit={async (values) => {
                        setWorking(true);
                        const result = await apiBookingSummarySave(getTourTypeIdV3(), g2BookingId, values.name, values.email, formattedContactNumber, values.notes);
                        if (result.status === StatusCodeOK) {
                            setContactDetails(values);

                            if (getAffiliate()) {
                                complete();
                            } else {
                                next();
                            }
                        }
                        setWorking(false);
                    }}>
                    {() => (
                        <Form className="flex flex-col flex-grow">
                            <div className="p-2 flex-col flex-grow">
                                <InputText name="name" label="Name" maxLength="100" wrapperClassName="w-full" />
                                <InputText name="email" label="Email address" maxLength="256" wrapperClassName="w-full" />
                                <InputText name="mobile" label="Mobile Number" maxLength="50" wrapperClassName="md:w-80" />
                                <div className="ml-1 mb-2 text-xs">Please double check you have entered the correct mobile number above as we will use this to send your departure text message.</div>
                                <InputCheckbox name="accept">
                                    I agree to the
                                    <a href={detailsGet().termsAndConditions} target="_blank" rel="noopener noreferrer" className="ml-1 underline">
                                        Terms and Conditions
                                    </a>
                                    .
                                </InputCheckbox>
                            </div>
                            <div className="button-bar">
                                <Buttons />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </ActionCard>
    );
};

export default StepContactDetails;
