import { Transition } from '@headlessui/react';
import React from 'react';
import FontAwesomeIcon from './font-awesome-icon';

const Modal = ({ icon, title, children, show }) => {
    return (
        <Transition show={show}>
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="flex items-center justify-center gap-2">
                                {icon && (
                                    <div className="flex items-center justify-center h-8 w-8 rounded-full bg-gray-200">
                                        <FontAwesomeIcon icon={icon} />
                                    </div>
                                )}
                                <div className="text-lg leading-6 font-semibold text-gray-900">{title}</div>
                            </div>
                            <div className="mt-2">{children}</div>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Transition>
    );
};

export default Modal;
