import axios from 'axios';

export const apiWorldPayStart = async (tourTypeId, g2BookingId, cardTry = 0) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/WorldPay/Start',
        data: { tourTypeId, g2BookingId, cardTry },
    });

    if (result.status === 500) {
        window.location.href = '/500';
    } else {
        return result.data;
    }
};

export const apiWorldPayComplete = async (tourTypeId, g2BookingId) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/WorldPay/Complete',
        data: { tourTypeId, g2BookingId },
    });

    if (result.status === 500) {
        window.location.href = '/500';
    } else {
        return result.data;
    }
};

export const apiWorldPayLog = async (tourTypeId, g2BookingId, message) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/WorldPay/Log',
        data: { tourTypeId, g2BookingId, status: message?.order?.status, message: JSON.stringify(message) },
    });

    if (result.status === 500) {
        window.location.href = '/500';
    } else {
        return result.data;
    }
};
